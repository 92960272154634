<template>
  <TransitionRoot  as="template" @after-leave="query = ''" appear>
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20" tabindex="0" @keyup.esc="$emit('toggle')">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
          <DialogPanel class="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
            <Combobox>
              <div class="relative">
                <SearchIcon class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400 select-none" aria-hidden="true" />
                <input type="text" class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 text-sm" placeholder="Search..." v-model="query" @change="startSearching" />
              </div>

              <ComboboxOptions v-if="foundThings.length > 0 && stillSearching === false" static class="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800">
                <ComboboxOption v-for="thing in foundThings" :key="thing.id" :value="thing" as="template" v-slot="{ active }">
                  <li :class="['cursor-pointer select-none px-4 py-2', active && 'bg-gray-500 text-white']" @click="$emit('setThing', thing)">
                    <div v-if="searchType==='clients'">{{ thing.name }}</div>

                    <div v-if="searchType==='products'">
                      <div class="flex items-center">
                        <div class="h-10 w-10 flex-shrink-0">
                            <img class="h-10 w-10 rounded-md object-contain" :src="thing.image.url.length>0 ? thing.image.url : `https://avatars.dicebear.com/api/initials/${encodeURIComponent(`Product${thing.id}`)}.svg`" :alt="thing.name" />
                        </div>
                        <div class="ml-2">
                          {{thing.name}} <span class="text-xs text-gray-400" v-if="thing.attribs.list.length>1">({{getAttribSnippet(thing.attribs.list)}})</span>
                        </div>
                      </div>

                    </div>
                  </li>


                </ComboboxOption>
              </ComboboxOptions>

              <p v-if="query !== '' && foundThings.length === 0 && stillSearching === false" class="p-4 text-sm text-gray-500">No results were found.</p>
              <p v-if="query !== '' &&  stillSearching === true" class="p-4 text-sm text-gray-500">Searching... Wait for results.</p>
            </Combobox>
            <div class="flex justify-center">
                <span class="text-xs p-2 select-none text-gray-500 hover:text-gray-800 cursor-pointer" @click="$emit('toggle')">Press "Esc" or click here to close.</span>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import axios from 'axios';
import { SearchIcon } from '@heroicons/vue/solid';
import {
  Combobox,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';

export default {
        name: "SearchInComplaint",
        props: ['type'],
        components: {
            Combobox,
            ComboboxOptions,
            ComboboxOption,
            Dialog,
            DialogPanel,
            TransitionChild,
            TransitionRoot,
            SearchIcon
        },
        data()
        {
            return {
              query: "",
              searchType: "clients",
              foundThings: [],
              stillSearching: false,
            }
        },
        created()
        {
          this.searchType = this.type;
        },
        methods:
        {
            getAttribSnippet(list)
            {
              let arrPhrases = [];
              if(list.length>=2)
              {
                
                for(let i=0;i<2; i++)
                {
                  arrPhrases.push(`${list[i].parent.name} - ${list[i].name}`)
                }
              }
              if(list.length === 1)
              {
                arrPhrases.push(`${list[0].parent.name} - ${list[0].name}`)
              }
              return arrPhrases.toString();
            },
            startSearching()
            {
              if(this.searchType === "clients")
              {
                this.find(this.query,"https://europe-central2-complaia.cloudfunctions.net/api/elastic/search/client");
              }
              if(this.searchType === "products")
              {
                this.find(this.query,"https://europe-central2-complaia.cloudfunctions.net/api/elastic/search/products");
              }
            },
            async find(query, link)
            {
                if(query.length < 3) return;
                this.stillSearching = true;
                const search = await axios.post(link, {
                    system: this.$store.state.userData.system.id,
                    phrase: query
                }).catch((error)=>
                {
                  this.stillSearching = false;
                  console.log(error);
                });
                if(search.data.result.length>0)
                {
                    this.foundThings = search.data.result;
                }
                this.stillSearching = false;
            }
        },
        computed:
        {

            
        }
    }



</script>