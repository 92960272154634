<template>
    <div>
        <h4 class="sr-only">Progress</h4>
        <p class="text-sm font-medium text-gray-900">Progress of action</p>
        <div class="mt-6" aria-hidden="true">
        <div class="bg-gray-200 rounded-full overflow-hidden">
            <div class="h-2 bg-gradient-to-r from-indigo-400 via-purple-400 to-blue-400 rounded-full" :style="`width: ${progress}%`" />
        </div>
        <div class="hidden sm:grid grid-cols-5 text-sm font-medium text-gray-600 mt-6">
            <div class="text-blue-500">Start</div>
            <div class="text-center text-blue-500">Basic information</div>
            <div class="text-center text-blue-500">Warehouse and location  </div>
            <div class="text-center text-blue-500">Costs, prices and attributes</div>
            <div class="text-right text-blue-500" >Finish</div>
        </div>
        </div>
    </div>
    <div class="flex sm:items-baseline sm:space-x-4 mt-4 mb-4">
        <router-link to="/dashboard/products/" class="text-sm font-medium text-purple-600 hover:text-purple-500 block cursor-pointer"><span aria-hidden="true"> &larr;</span> Back to product menu</router-link>
    </div>

    <form class="space-y-8 divide-y divide-gray-200 mt-4" v-show="step === 1">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Information</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Name, thumbnail photo and additional information.</p>
                </div>
                <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Full Name* </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input type="text" maxlength="40" v-model="product.name" class="flex-1 block w-full focus:ring-purple-500 focus:border-purple-500 min-w-0 rounded-md text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="cover-photo" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Photo </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2" >
                            <div class="group flex justify-center align-center rounded-md border-2 border-gray-300 overflow-hidden w-40 h-40" v-show="imgPreviewSrc.length>0">
                                <img @click="triggerImgClick" :src="imgPreviewSrc" class="object-center object-contain w-full group-hover:opacity-70 cursor-pointer">
                            </div>
                            <div v-show="imgPreviewSrc.length>0" class="mt-2 mb-2 flex items-center justify-center w-40">
                                <button type="button" @click.prevent="clearImg" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Clear photo</button>
                            </div>
                            
                            
                            <div class="transition ease-in duration-200 max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:opacity-70 cursor-pointer select-none" @click="triggerImgClick" v-show="imgPreviewSrc.length === 0" @drop.prevent="dropFile" @dragenter.prevent @dragover.prevent>
                                <div class="space-y-1 text-center">
                                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div class="flex items-center text-sm text-gray-600 pointer-events-none">
                                    <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500">
                                    <span>Upload a file</span>
                                    <input id="file-upload" name="file-upload" type="file" accept="image/*" class="sr-only" ref="mainImgUploader" @change="setMainImage" />
                                    </label>
                                    <p class="pl-1">or drag and drop</p>
                                </div>
                                <p class="text-xs text-gray-500">.PNG, .JPG, .GIF, .SVG up to 2 mb</p>
                                <p class="text-xs text-gray-500">Best experience: 160x160 px</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Category</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <!-- OLD VERSION -->
                                <!-- <select @change="setCategory" v-model="setOptions.category" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                                    <option value="">None</option>
                                    <option v-for="(category,categoryIndex) in options.category" :value="categoryIndex" :key="categoryIndex">{{category.name}} - (ID: {{category.id}})</option>
                                </select> -->
                                <Listbox class="w-full" as="div" v-model="setOptions.category">
                                    <div class="mt-1 relative">
                                        <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                                        <span class="flex items-center truncate">{{translateCategory(setOptions.category)}}</span>
                                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                        </ListboxButton>

                                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                                            <ListboxOption @click.prevent="setCategory" as="template" value="" v-slot="{ active, selected }">
                                            <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">None</span>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            </li>
                                            </ListboxOption>
                                            <ListboxOption @click.prevent="setCategory" as="template" v-for="(category,categoryIndex) in options.category" :value="categoryIndex" :key="categoryIndex" v-slot="{ active, selected }">
                                            <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{category.name}} - (ID: {{category.id}})</span>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            </li>
                                            </ListboxOption>
                                        </ListboxOptions>
                                        </transition>
                                    </div>
                                </Listbox>
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Manufacturer</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <!-- OLD VERSION -->
                                <!-- <select @change="setManufacturer" v-model="setOptions.manufacturer" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                                    <option value="">None</option>
                                    <option v-for="(manufacturer,manufacturerIndex) in options.manufacturer" :value="manufacturerIndex" :key="manufacturerIndex">{{manufacturer.name}} - (ID: {{manufacturer.id}})</option>
                                </select> -->
                                <Listbox class="w-full" as="div" v-model="setOptions.manufacturer">
                                    <div class="mt-1 relative">
                                        <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                                        <span class="flex items-center truncate">{{translateManufacturer(setOptions.manufacturer)}}</span>
                                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                        </ListboxButton>

                                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                                            <ListboxOption @click.prevent="setManufacturer" as="template" value="" v-slot="{ active, selected }">
                                            <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">None</span>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            </li>
                                            </ListboxOption>
                                            <ListboxOption @click.prevent="setManufacturer" as="template" v-for="(manufacturer,manufacturerIndex) in options.manufacturer" :value="manufacturerIndex" :key="manufacturerIndex" v-slot="{ active, selected }">
                                            <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{manufacturer.name}} - (ID: {{manufacturer.id}})</span>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            </li>
                                            </ListboxOption>
                                        </ListboxOptions>
                                        </transition>
                                    </div>
                                </Listbox>
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Model</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input v-model="product.model" type="text" class="flex-1 block w-full focus:ring-purple-500 focus:border-purple-500 min-w-0 rounded-md text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> EAN</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input v-model="product.ean" maxlength="12" type="text" class="flex-1 block w-full focus:ring-purple-500 focus:border-purple-500 min-w-0 rounded-md text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> SKU</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input v-model="product.sku" maxlength="12" type="text" class="flex-1 block w-full focus:ring-purple-500 focus:border-purple-500 min-w-0 rounded-md text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Description </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <textarea v-model="product.description" rows="3" class="max-w-lg shadow-sm block w-full focus:ring-purple-500 focus:border-purple-500 text-sm border border-gray-300 rounded-md" />
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Extra Field 1</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input v-model="product.extraField1" type="text" class="flex-1 block w-full focus:ring-purple-500 focus:border-purple-500 min-w-0 rounded-md text-sm border-gray-300" />
                            </div>
                            <p class="mt-2 text-xs text-gray-500" >Field for additional use.</p>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Extra Field 2</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input type="text" v-model="product.extraField2" class="flex-1 block w-full focus:ring-purple-500 focus:border-purple-500 min-w-0 rounded-md text-sm border-gray-300" />
                            </div>
                            <p class="mt-2 text-xs text-gray-500" >Field for additional use.</p>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Extra Field 3</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input type="text" v-model="product.extraField3" class="flex-1 block w-full focus:ring-purple-500 focus:border-purple-500 min-w-0 rounded-md text-sm border-gray-300" />
                            </div>
                            <p class="mt-2 text-xs text-gray-500">Field for additional use.</p>
                        </div>
                    </div>



                </div>
            </div>            


        </div>

        <div class="pt-5">
        <div class="flex flex-col-reverse sm:flex-row justify-end">
            <button type="button" @click.prevent="sendBack" class="bg-white mt-2 sm:mt-0 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Back</button>
            <button type="submit" @click.prevent="changeStep(2)" :class="{'sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-purple-300': !validation1, 'bg-purple-600 hover:bg-purple-700 focus:ring-purple-500': validation1}">Step 2: Warehouse and location</button>
        </div>
        </div>
    </form>

    



    <form class="space-y-8 divide-y divide-gray-200 mt-4" v-show="step === 2">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Storage </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Information about item placing.</p>
                </div>
                <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Warehouse</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <!-- OLD VERSION -->
                                <!-- <select @change="setWarehouse" v-model="setOptions.warehouse" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                                    <option value="">Not specified</option>
                                    <option v-for="(warehouse,warehouseIndex) in options.warehouse" :value="warehouseIndex" :key="warehouseIndex">{{warehouse.name}} - (ID: {{warehouse.id}})</option>
                                </select> -->
                                <Listbox class="w-full" as="div" v-model="setOptions.warehouse">
                                    <div class="mt-1 relative">
                                        <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                                        <span class="flex items-center truncate">{{translateWarehouse(setOptions.warehouse)}}</span>
                                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                        </ListboxButton>

                                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                                            <ListboxOption @click.prevent="setWarehouse" as="template" value="" v-slot="{ active, selected }">
                                            <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Not specified</span>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            </li>
                                            </ListboxOption>
                                            <ListboxOption @click.prevent="setWarehouse" as="template" v-for="(warehouse,warehouseIndex) in options.warehouse" :value="warehouseIndex" :key="warehouseIndex" v-slot="{ active, selected }">
                                            <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{warehouse.name}} - (ID: {{warehouse.id}})</span>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            </li>
                                            </ListboxOption>
                                        </ListboxOptions>
                                        </transition>
                                    </div>
                                </Listbox>
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Location</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input v-model="product.storage.location" type="text" class="flex-1 block w-full focus:ring-purple-500 focus:border-purple-500 min-w-0 rounded-md text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Additional info</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input v-model="product.storage.additionalInfo" type="text" class="flex-1 block w-full focus:ring-purple-500 focus:border-purple-500 min-w-0 rounded-md text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                </div>

                <div class="mt-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">External Source</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Reference to external source of an item.</p>
                </div>

                <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Link</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input v-model="product.link.href" type="text" class="flex-1 block w-full focus:ring-purple-500 focus:border-purple-500 min-w-0 rounded-md text-sm border-gray-300" />
                            </div>
                            <p class="mt-2 text-xs text-gray-500">You may want to have quick access to your item. Provide link in this field. </p>
                            <p class="mt-2 text-xs text-gray-500 italic">Examples: </p>
                            <p class="mt-2 text-xs text-gray-500 italic">https://your-shop/product</p>
                            <p class="mt-2 text-xs text-gray-500 italic">https://item-supplier/product</p>
                            
                        </div>
                    </div>



                </div>


            </div>            
        </div>

        <div class="pt-5">
        <div class="flex flex-col-reverse sm:flex-row justify-end">
            <button type="button" @click.prevent="changeStep(1)" class="bg-white mt-2 sm:mt-0 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Previous step</button>
            <button type="submit" @click.prevent="changeStep(3)" :class="{'sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-purple-300': !validation1, 'bg-purple-600 hover:bg-purple-700 focus:ring-purple-500': validation1}">Step 3: Cost, prices and attributes</button>
        </div>
        </div>
    </form>

    <form class="space-y-8 divide-y divide-gray-200 mt-4" v-show="step === 3">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Costs and prices</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Prices</p>
                </div>
                <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Production / Acquisition Cost</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div>
                                <div class="mt-1 max-w-lg relative rounded-md shadow-sm">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span class="text-gray-500 text-sm"> $ </span>
                                </div>
                                <input type="number" @change="checkProductionCost" v-model="product.pricesAndCosts.productionCost" name="price" id="price" class="focus:ring-purple-500 focus:border-purple-500 block w-full pl-7 pr-12 text-sm border-gray-300 rounded-md" placeholder="Production Cost" aria-describedby="price-currency" />
                                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span class="text-gray-500 text-sm" id="price-currency"> USD </span>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Sell Price</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div>
                                <div class="mt-1 max-w-lg relative rounded-md shadow-sm">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span class="text-gray-500 text-sm"> $ </span>
                                </div>
                                <input type="number" @change="checkSellPrice" name="price" v-model="product.pricesAndCosts.sellPrice" id="price" class="focus:ring-purple-500 focus:border-purple-500 block w-full pl-7 pr-12 text-sm border-gray-300 rounded-md" placeholder="Sell Price" aria-describedby="price-currency" />
                                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span class="text-gray-500 text-sm" id="price-currency"> USD </span>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Complaint Cost</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div>
                                <div class="mt-1 max-w-lg relative rounded-md shadow-sm">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span class="text-gray-500 text-sm"> $ </span>
                                </div>
                                <input type="number" @change="checkComplaintCost" name="price" id="price" class="focus:ring-purple-500 focus:border-purple-500 block w-full pl-7 pr-12 text-sm border-gray-300 rounded-md" placeholder="Complaint Cost" v-model="product.pricesAndCosts.complaintCost" aria-describedby="price-currency" />
                                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span class="text-gray-500 text-sm" id="price-currency"> USD </span>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                   
        </div>
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div class="mt-4">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Attributes</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Create specification of product with attributes.</p>
                </div>
                <div class="mt-2 flex justify-end">
                    <button type="button" class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                        <PlusSmIconOutline @click.prevent="showModal = true" class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
                <div class="mt-4">
                    <ul role="list" class="-my-5 divide-y divide-gray-200" v-if="product.attribs.list.length>0">
                        <li v-for="(attrib,atrbIndx) in product.attribs.list" :key="attrib.id" class="py-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <div :class="[ 'bg-yellow-200 flex-shrink-0 flex items-center justify-center w-16 p-0.5 rounded-md text-black text-sm font-medium select-none']">
                                        {{attrib.parent.name.substring(0,3).toUpperCase()}}
                                    </div>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm font-medium text-gray-900 truncate">
                                        <router-link :to="`/dashboard/modifyAttribute?attrib=${attrib.parent.id}`" target="_blank" class="text-xs opacity-50 hover:text-purple-500">({{attrib.parent.name}})</router-link> {{ attrib.name }} 
                                    </p>
                                    <p class="text-sm text-gray-500 truncate">
                                        ID: {{ attrib.id }}
                                    </p>
                                </div>
                                <div>
                                    <button class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50" @click.prevent="product.attribs.list.splice(atrbIndx,1)"> Delete </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <EmptyState v-if="product.attribs.list.length === 0"></EmptyState>
                    <router-link to="/dashboard/product/attributes" target="_blank" class="cursor-pointer mt-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-purple-700 bg-purple-100 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Edit groups of attributes</router-link>
                </div>


            </div>
       
        </div>

        <div class="pt-5">
            <div class="flex flex-col-reverse sm:flex-row justify-end">
                <button type="button" @click.prevent="changeStep(2)" class="bg-white mt-2 sm:mt-0 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Previous step</button>
                <button type="submit" @click.prevent="changeStep(4)" :class="{'sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-purple-300': !validation1, 'bg-purple-600 hover:bg-purple-700 focus:ring-purple-500': validation1}">Create product</button>
            </div>
        </div>
    </form>
    <AttribListModal :show="showModal" @close="closeModal" @addSelectedOptions="pushToAttribList"></AttribListModal>

</template>

<script>
    import moment from 'moment';
    import axios from 'axios';
    import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/vue/outline'
    import {db,storage} from "@/firebase/gfbconf.js";
    import AttribListModal from '@/components/AttribListModal.vue';
    import EmptyState from '../components/EmptyState.vue';
    import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
    import { CheckIcon, SelectorIcon} from '@heroicons/vue/solid'
    export default {
        name: "ProductAdd",
        data()
        {
            return {
                fileLimitSize: 2097152,
                showModal: false,
                setOptions: {
                    category: "",
                    manufacturer: "",
                    warehouse: ""
                },
                imgPreviewSrc: "",
                progress: 0,
                step: 1,
                options: {
                    category: [],
                    manufacturer: [],
                    warehouse: []
                },
                product: {
                    name: "",
                    model: "",
                    active: true,
                    meta: {
                        createdBy: null,
                        createdDate: null,
                        lastModificationDate: null,
                        listOfChanges: []
                    },
                    ean: "",
                    sku: "",
                    image: {
                        url: "",
                        ref: null
                    },
                    manufacturer: {
                        name: "",
                        ref: null,
                        id: ""
                    },
                    category: {
                        name: "",
                        ref: null,
                        id: "",
                    },
                    elasticSearch: {
                        docId: "",
                        index: "",
                        creationDate: ""
                    },
                    extraField1: "",
                    extraField2: "",
                    extraField3: "",
                    description: "",
                    pricesAndCosts: {
                        currency: "USD",
                        sellPrice: 0,
                        complaintCost: 0,
                        productionCost: 0
                    },
                    storage: {
                        warehouse: {
                            name: "",
                            ref: null,
                            id: ""
                        },
                        location: "",
                        additionalInfo: ""
                    },
                    link: {
                        href: ""
                    },
                    attribs: {
                        list: []
                    }
                }
            }
        },
        components: {
            PlusSmIconOutline,
            AttribListModal,
            EmptyState,
            Listbox, ListboxButton, ListboxOption, ListboxOptions,
            CheckIcon, SelectorIcon
        },
        created()
        {
            this.allToFixed2();
            this.getAdditionalOptions();
        },
        methods:
        {
            translateCategory(id){
                if(id !== ""){
                    return this.options.category[id].name + " - (ID: " + this.options.category[id].id + ")"
                }else{
                    return "None"
                }
            },
            translateManufacturer(id){
                if(id !== ""){
                    return this.options.manufacturer[id].name + " - (ID: " + this.options.manufacturer[id].id + ")"
                }else{
                    return "None"
                }
            },
            translateWarehouse(id){
                if(id !== ""){
                    return this.options.warehouse[id].name + " - (ID: " + this.options.warehouse[id].id + ")"
                }else{
                    return "Not specified"
                }
            },
            pushToAttribList(buffer)
            {
                for(let i=0; i<buffer.length; i++)
                {
                    this.product.attribs.list.push(buffer[i]);
                }
            },
            closeModal()
            {
                this.showModal = false;
            },
            setManufacturer()
            {
                let newValue = this.options.manufacturer[this.setOptions.manufacturer];
                if(newValue === undefined || newValue === "")
                {
                    this.product.manufacturer.name = "";
                    this.product.manufacturer.ref = null;
                    this.product.manufacturer.id = "";
                }
                else
                {
                    this.product.manufacturer.name = newValue.name;
                    this.product.manufacturer.id = newValue.id.toString();
                    this.product.manufacturer.ref = this.$store.state.userData.system.ref.collection("Manufacturers").doc(newValue.id.toString());
                }
            },
            setWarehouse()
            {
                let newValue = this.options.warehouse[this.setOptions.warehouse];
                if(newValue === undefined || newValue === "")
                {
                    this.product.storage.warehouse.name = "";
                    this.product.storage.warehouse.ref = null;
                    this.product.storage.warehouse.id = "";
                }
                else
                {
                    this.product.storage.warehouse.name = newValue.name;
                    this.product.storage.warehouse.id = newValue.id.toString();
                    this.product.storage.warehouse.ref = this.$store.state.userData.system.ref.collection("Warehouses").doc(newValue.id.toString());
                }
            },
            setCategory()
            {
                let newValue = this.options.category[this.setOptions.category];
                if(newValue === undefined || newValue === "")
                {
                    this.product.category.name = "";
                    this.product.category.ref = null;
                    this.product.category.id = "";
                }
                else
                {
                    this.product.category.name = newValue.name;
                    this.product.category.id = newValue.id.toString();
                    this.product.category.ref = this.$store.state.userData.system.ref.collection("Categories").doc(newValue.id.toString());
                }
            },
            async getAdditionalOptions()
            {
                const categoryDocsResult = await this.$store.state.userData.system.ref.collection("Categories").get();
                for(let i=0; i<categoryDocsResult.docs.length; i++)
                {
                    this.options.category.push(categoryDocsResult.docs[i].data());
                }
                const manufacturersDocsResult = await this.$store.state.userData.system.ref.collection("Manufacturers").get();
                for(let i=0; i<manufacturersDocsResult.docs.length; i++)
                {
                    this.options.manufacturer.push(manufacturersDocsResult.docs[i].data());
                }
                const warehousesDocsResult = await this.$store.state.userData.system.ref.collection("Warehouses").get();
                for(let i=0; i<warehousesDocsResult.docs.length; i++)
                {
                    this.options.warehouse.push(warehousesDocsResult.docs[i].data());
                }
            },
            dropFile(e)
            {
                if(e.dataTransfer.files.length>0)
                {
                    if(e.dataTransfer.files[0].type.split('/')[0] === 'image')
                    {
                        if(e.dataTransfer.files[0].size <= this.fileLimitSize)
                        {
                            const fileReader = new FileReader();
                            fileReader.readAsDataURL(e.dataTransfer.files[0]);
                            fileReader.addEventListener("load", () =>
                            {
                                this.imgPreviewSrc = fileReader.result;
                            })
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "File is more than 2MB",
                                subheader: `Image is too large.`,
                                success: false
                            }); 
                        }
                    }
                    else
                    {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "File is not image.",
                            subheader: `File should be image type.`,
                            success: false
                        });   
                    }
                }
                
            },
            triggerImgClick()
            {
                this.$refs.mainImgUploader.click();
            },
            clearImg()
            {
                this.imgPreviewSrc = '';
                this.$refs.mainImgUploader.value = "";
            },
            allToFixed2()
            {
                this.product.pricesAndCosts.complaintCost = 0;
                this.product.pricesAndCosts.productionCost = 0;
                this.product.pricesAndCosts.sellPrice = 0;
                this.product.pricesAndCosts.complaintCost = this.product.pricesAndCosts.complaintCost.toFixed(2);
                this.product.pricesAndCosts.productionCost = this.product.pricesAndCosts.productionCost.toFixed(2);
                this.product.pricesAndCosts.sellPrice = this.product.pricesAndCosts.sellPrice.toFixed(2);
            },
            checkProductionCost()
            {
                if(this.product.pricesAndCosts.productionCost.length === 0)
                {
                    this.product.pricesAndCosts.productionCost = 0;
                }
                if(this.product.pricesAndCosts.productionCost < 0)
                {
                    this.product.pricesAndCosts.productionCost = 0;
                }
                else
                {
                    this.product.pricesAndCosts.productionCost = parseFloat(this.product.pricesAndCosts.productionCost);
                }
                this.product.pricesAndCosts.productionCost = this.product.pricesAndCosts.productionCost.toFixed(2);
            },
            checkSellPrice()
            {
                if(this.product.pricesAndCosts.sellPrice.length === 0)
                {
                    this.product.pricesAndCosts.sellPrice = 0;
                }
                if(this.product.pricesAndCosts.sellPrice < 0)
                {
                    this.product.pricesAndCosts.sellPrice = 0;
                }
                else
                {
                    this.product.pricesAndCosts.sellPrice = parseFloat(this.product.pricesAndCosts.sellPrice);
                }
                this.product.pricesAndCosts.sellPrice = this.product.pricesAndCosts.sellPrice.toFixed(2);
            },
            checkComplaintCost()
            {
                if(this.product.pricesAndCosts.complaintCost.length === 0)
                {
                    this.product.pricesAndCosts.complaintCost = 0;
                }
                if(this.product.pricesAndCosts.complaintCost < 0)
                {
                    this.product.pricesAndCosts.complaintCost = 0;
                }
                else
                {
                    this.product.pricesAndCosts.complaintCost = parseFloat(this.product.pricesAndCosts.complaintCost);
                }
                this.product.pricesAndCosts.complaintCost = this.product.pricesAndCosts.complaintCost.toFixed(2);
            },
            setMainImage()
            {
                if(this.$refs.mainImgUploader.files.length>0)
                {
                    if(this.$refs.mainImgUploader.files[0].size <= this.fileLimitSize)
                    {
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(this.$refs.mainImgUploader.files[0]);
                        fileReader.addEventListener("load", () =>
                        {
                            this.imgPreviewSrc = fileReader.result;
                        })
                    }
                    else
                    {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "File is more than 2MB",
                            subheader: `Image is too large.`,
                            success: false
                        });                    
                    }
                }
            },
            filenameGenerator(length)
            {
                let result           = `${this.$store.state.userData.system.id}_prod_`;
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for ( let i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * 
                    charactersLength));
                }
                return result;
            },
            async addProductToDb()
            {
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Creating new product..",
                    subheader: `Do not close this tab.`,
                });
                try
                {
                    // Upload an image
                    if(this.$refs.mainImgUploader.files.length>0)
                    {
                        const storageRef = storage.ref();
                        const filename = this.filenameGenerator(10);
                        const path = `systems/${this.$store.state.userData.system.id}/products/${filename}`;
                        const imageRef = storageRef.child(path);
                        await imageRef.put(this.$refs.mainImgUploader.files[0]);
                        const fileUrl = await imageRef.getDownloadURL();
                        this.product.image.url = fileUrl;
                        this.product.image.ref = path;
                    }
                    // Add product to elastic search index
                    const elasticResponse = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/elastic/add/product", {
                        doc: {
                            name: this.product.name,
                            manufacturer: this.product.manufacturer.name,
                            model: this.product.model,
                            ean: this.product.ean,
                            sku: this.product.sku,
                            warehouse: this.product.storage.warehouse.name,
                            location: this.product.storage.location,
                            category: this.product.category.name
                          },
                        system: this.$store.state.userData.system.id
                    }).catch((error)=>
                    {
                        console.log(error);
                    });
                    if(elasticResponse.data.status === "ok")
                    {
                        this.product.elasticSearch = elasticResponse.data.elasticSearch;
                    }
                    this.product.meta.createdDate = moment().toDate();
                    this.product.meta.lastModificationDate = moment().toDate();
                    this.product.meta.createdBy = this.$store.state.user.displayName;
                    this.product.meta.userId = this.$store.state.user.uid;
                    // Convert to float to be sure to have this in good format in db.
                    this.product.pricesAndCosts.complaintCost = parseFloat(this.product.pricesAndCosts.complaintCost);
                    this.product.pricesAndCosts.productionCost = parseFloat(this.product.pricesAndCosts.productionCost);
                    this.product.pricesAndCosts.sellPrice = parseFloat(this.product.pricesAndCosts.sellPrice);

                    const itemRef = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Products");
                    const resultOfAdding = await itemRef.add(
                            this.product
                    )
                    // Update current doc id  
                    itemRef.doc(resultOfAdding.id).update({id: resultOfAdding.id}).catch((error)=>
                    {
                            console.log(error);
                    });
                    this.addProductToAnalitycs()
                    // Add event to event list.
                    this.$store.state.userData.system.ref.collection("Events").add(
                    {
                    user: {
                        name: this.$store.state.user.displayName,
                        photo: this.$store.state.user.photoURL
                    },
                    creationDate: moment().toDate(),
                    eventName: `Added new product: ${this.product.name}.`
                    }).catch((error)=>
                    {
                    console.log(error);
                    });
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Product created with success!",
                        subheader: `Your product is now ready.`,
                        success: true
                    });  
                    this.sendBack();
                }
                catch(error)
                {
                    console.log(error);
                }
                

            },
            async addProductToAnalitycs() {
                const refToYear = this.$store.state.userData.system.ref.collection('Analytics').doc(moment().year().toString())
                let currentMonth = moment(moment().month() + 1, 'MM').format('MMMM')
                let globalTemplate = {
                    complaints: {
                    active: 0,
                    addedByClient: 0,
                    addedManually: 0,
                    casesAmount: 0,
                    chargeback: {
                        quantity: 0,
                        worth: 0
                    },
                    complainedProductsQuantity: 0,
                    complaintsWorth: 0,
                    created: 0,
                    declined: 0,
                    deliveryData: {
                        delivery: 0,
                        deliveryAndPickup: 0,
                        pickup: 0
                    },
                    finished: 0,
                    priorityStats: {
                        Level1: 0,
                        Level2: 0,
                        Level3: 0,
                        Level4: 0,
                        Level5: 0,
                    }
                    },
                    productCreator: {
                    added: 0,
                    deleted: 0
                    },
                    products: []
                }
                // prepare analitycs objects
                let yearData = (await refToYear.get()).data()
                if(yearData === undefined) {
                    yearData = {}
                    yearData.year = moment().year()
                    yearData.global = JSON.parse(JSON.stringify(globalTemplate))
                }

                let monthData = yearData[moment(moment().month() + 1, 'MM').format('MMMM')]
                if(monthData === undefined) {
                    yearData[currentMonth] = {}
                    yearData[currentMonth].global = JSON.parse(JSON.stringify(globalTemplate))
                    yearData[currentMonth].days = {} 
                    monthData = yearData[moment(moment().month() + 1, 'MM').format('MMMM')]
                }

                let dayData = monthData.days[moment().date()]
                if(dayData === undefined) {
                    monthData.days[moment().date()] = {}
                    monthData.days[moment().date()].global = JSON.parse(JSON.stringify(globalTemplate))
                    dayData = monthData.days[moment().date()]
                }

                yearData.global.productCreator.added += 1
                monthData.global.productCreator.added += 1
                dayData.global.productCreator.added += 1
                await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Analytics").doc(moment().year().toString()).set(yearData);
            },
            sendBack()
            {
                this.$router.push("/dashboard/products")
            },
            changeStep(val)
            {
                switch(val)
                {
                    case 1: 
                        this.progress = 0;
                        this.step = 1;
                        break;
                    case 2:
                        if(this.validation1 === true)
                        {
                            this.step = 2;
                            this.progress = 50;
                        }
                        break;
                    case 3:
                        this.step = 3;
                        this.progress = 90;
                        break;
                    case 4:
                        this.step = 4;
                        this.progress = 100;
                        this.addProductToDb();
                }
            }
        },
        computed:
        {
            validation1()
            {
                if(this.product.name.length>0)
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }
        }
    }
</script>
<style lang="scss" scoped>

</style>