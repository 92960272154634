<template>
<!-- Basics -->
<div class="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900 select-none">Basic Information</h3>
    <p class="mt-1 max-w-2xl text-sm text-gray-500">
        <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800" v-if="complaint.priority === 'Level 1'"> {{complaint.priority}} </span>
        <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800" v-if="complaint.priority === 'Level 2'"> {{complaint.priority}} </span>
        <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800" v-if="complaint.priority === 'Level 3'"> {{complaint.priority}} </span>
        <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800" v-if="complaint.priority === 'Level 4'"> {{complaint.priority}} </span>
        <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800" v-if="complaint.priority === 'Level 5'"> {{complaint.priority}} </span>
    </p>
  </div>
  <div class="border-t border-gray-200">
    <dl>
      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500 select-none">Full name</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{complaint.client.name.length > 0 ? complaint.client.name : "----"}}</dd>
      </div>
      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500 select-none">Email</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{complaint.client.email.length>0 ? complaint.client.email : "----"}}</dd>
      </div>
      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500 select-none">Phone</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{complaint.client.phoneNum.length > 0 ? complaint.client.phoneNum : "----"}}</dd>
      </div>
      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500 select-none">Address</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span class="block" v-if="complaint.client.address.length>0"><span class="font-medium text-xs select-none text-gray-500">Street:</span> {{complaint.client.address}}</span>
            <span class="block" v-if="complaint.client.city.length>0"><span class="font-medium text-xs select-none text-gray-500">City:</span> {{complaint.client.city}}</span>
            <span class="block" v-if="complaint.client.postCode.length>0"><span class="font-medium text-xs select-none text-gray-500">Post Code:</span> {{complaint.client.postCode}}</span>
            <span class="block" v-if="complaint.client.country.length>0"><span class="font-medium text-xs select-none text-gray-500">Country:</span> {{complaint.client.country}}</span>
        </dd>
      </div>
      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500 select-none">Creation date</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <input type="date" :value="complaint.createdDate.asText" :class="{'border-gray-300 bg-gray-100 select-none focus:outline-none text-xs focus:ring-purple-500 focus:border-purple-500 rounded-md': true}" readonly>
        </dd>
      </div>
      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500 select-none">Planned finalization</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <input type="date" :value="complaint.scheduledEnd.asText" :class="{'border-gray-300 bg-gray-100 select-none focus:outline-none text-xs focus:ring-purple-500 focus:border-purple-500 rounded-md': true}" readonly>
        </dd>
      </div>
      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500 select-none">Created by</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{complaint.meta.creatorName}}
        </dd>
      </div>
      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500 select-none">Assigned to</dt>
        <dd class="mt-1 flex items-center text-sm text-gray-900 sm:mt-0 sm:col-span-2" v-if="attendant !== undefined && attendant !== null">
            <div class="group flex justify-center align-center rounded-md overflow-hidden w-8 h-8 mr-2" v-if="attendant.photoURL.length>0">
                <img :src="attendant.photoURL" class="object-center object-contain w-full select-none">
            </div>
            <span>{{attendant.name}} {{attendant.surname}}</span>
        </dd>
      </div>


 
    </dl>
  </div>
</div>

<!-- Delivery -->
<div class="bg-white shadow overflow-hidden sm:rounded-lg mt-4" v-if="complaint.delivery.type !== 'Not applicable'">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900 select-none">{{complaint.delivery.type}}</h3>
  </div>
  <div class="border-t border-gray-200">
    <dl>
      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500 select-none">Provider</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{complaint.delivery.courier.length > 0 ? complaint.delivery.courier : "----"}}</dd>
      </div>
      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500 select-none">Details</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="block" v-if="complaint.delivery.name.length>0"><span class="font-medium text-xs select-none text-gray-500">Name:</span> {{complaint.delivery.name}}</span>
            <span class="block" v-if="complaint.delivery.address.length>0"><span class="font-medium text-xs select-none text-gray-500">Street:</span> {{complaint.delivery.address}}</span>
            <span class="block" v-if="complaint.delivery.city.length>0"><span class="font-medium text-xs select-none text-gray-500">City:</span> {{complaint.delivery.city}}</span>
            <span class="block" v-if="complaint.delivery.postCode.length>0"><span class="font-medium text-xs select-none text-gray-500">Post Code:</span> {{complaint.delivery.postCode}}</span>
            <span class="block" v-if="complaint.delivery.country.length>0"><span class="font-medium text-xs select-none text-gray-500">Country:</span> {{complaint.delivery.country}}</span>
            <span class="block" v-if="complaint.delivery.phoneNum.length>0"><span class="font-medium text-xs select-none text-gray-500">Phone Number:</span> {{complaint.delivery.phoneNum}}</span>
        </dd>
      </div>
      <!-- <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500 select-none"></dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="block" v-if="complaint.delivery.name.length>0"><span class="font-medium text-xs select-none text-gray-500">Name:</span> {{complaint.delivery.name}}</span>
            <span class="block" v-if="complaint.delivery.address.length>0"><span class="font-medium text-xs select-none text-gray-500">Street:</span> {{complaint.delivery.address}}</span>
            <span class="block" v-if="complaint.delivery.city.length>0"><span class="font-medium text-xs select-none text-gray-500">City:</span> {{complaint.delivery.city}}</span>
            <span class="block" v-if="complaint.delivery.postCode.length>0"><span class="font-medium text-xs select-none text-gray-500">Post Code:</span> {{complaint.delivery.postCode}}</span>
            <span class="block" v-if="complaint.delivery.country.length>0"><span class="font-medium text-xs select-none text-gray-500">Country:</span> {{complaint.delivery.country}}</span>
            <span class="block" v-if="complaint.delivery.phoneNum.length>0"><span class="font-medium text-xs select-none text-gray-500">Phone Number:</span> {{complaint.delivery.phoneNum}}</span>
        </dd>
      </div> -->
    </dl>
  </div>
</div>

<!-- Chargeback -->
<div class="bg-white shadow overflow-hidden sm:rounded-lg mt-4" v-if="complaint.chargeback.applicable">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900 select-none">Chargeback</h3>
  </div>
  <div class="border-t border-gray-200">
    <dl>
      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500 select-none">Done</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{complaint.chargeback.done ? "Yes" : "No"}}</dd>
      </div>
      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500 select-none">Details</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span class="block" v-if="complaint.chargeback.iban.length>0"><span class="font-medium text-xs select-none text-gray-500">IBAN:</span> {{complaint.chargeback.iban}}</span>
            <span class="block" v-if="complaint.chargeback.swift.length>0"><span class="font-medium text-xs select-none text-gray-500">SWIFT:</span> {{complaint.chargeback.swift}}</span>
            <span class="block" v-if="complaint.chargeback.recipient.length>0"><span class="font-medium text-xs select-none text-gray-500">Recipient:</span> {{complaint.chargeback.recipient}}</span>
            <span class="block" v-if="complaint.chargeback.address>0"><span class="font-medium text-xs select-none text-gray-500">Full address:</span> {{complaint.chargeback.address}}</span>
            <span class="block" v-if="complaint.chargeback.transferTitle.length>0"><span class="font-medium text-xs select-none text-gray-500">Transfer title:</span> {{complaint.chargeback.transferTitle}}</span>
            <span class="block"><span class="font-medium text-xs select-none text-gray-500">Amount:</span> {{complaint.chargeback.amount.toFixed(2)}} {{complaint.chargeback.currency}}</span>
        </dd>
      </div>
    </dl>
  </div>
</div>

<!-- EXTERNAL IDENTIFIERS -->
<div class="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900 select-none">External Identifiers</h3>

  </div>
  <div class="border-t border-gray-200">
    <dl>
      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500 select-none">External order number</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span v-if="complaint.externalIds.orderNum.length>0">{{complaint.externalIds.orderNum}}</span>
            <span v-if="complaint.externalIds.orderNum.length===0" class="text-gray-400 text-xs select-none">-----</span>
        </dd>
      </div>
      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500 select-none">External client number</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span v-if="complaint.externalIds.clientNum.length>0">{{complaint.externalIds.clientNum}}</span>
            <span v-if="complaint.externalIds.clientNum.length===0" class="text-gray-400 text-xs select-none">-----</span>
        </dd>
      </div>
      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500 select-none">Invoice number</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span v-if="complaint.externalIds.invoiceNum.length>0">{{complaint.externalIds.invoiceNum}}</span>
            <span v-if="complaint.externalIds.invoiceNum.length===0" class="text-gray-400 text-xs select-none">-----</span>
        </dd>
      </div>
    </dl>
  </div>
</div>

<div class="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
    <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">Cases</h3>
    </div>
</div>

<div class="bg-white shadow overflow-hidden sm:rounded-lg mt-4" v-for="cs in complaint.cases" :key="cs.id">

<div class="border-t border-gray-200 px-4 py-5 sm:px-6">
    <dl class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
    <div class="col-span-2 sm:col-span-1 flex items-center">
        <div class="group flex justify-center align-center rounded-md overflow-hidden w-20 h-20 shadow p-2 border-gray-200" v-if="cs.item.imageAtAddingTime.length>0">
            <img :src="cs.item.imageAtAddingTime" class="object-center object-scale-down w-full select-none ">
        </div>
        
        <div :class="{'ml-4': cs.item.imageAtAddingTime.length>0}">
            <dt class="text-sm font-medium text-gray-500 select-none">Product</dt>
            <dd class="mt-1 text-sm text-gray-900">{{cs.item.name}}</dd>
        </div>

    </div>

    <div class="sm:col-span-2">
        <dt class="text-sm font-medium text-gray-500 select-none">Complaint cost</dt>
        <dd class="mt-1 text-sm text-gray-900">{{cs.item.complaintCostAtAddingTime.toFixed(2)}} {{cs.item.currencyAtAddingTime}}</dd>
    </div>

    <div class="sm:col-span-2" v-if="cs.item.attributesAtAddingTime.length>0">
        <dt class="text-sm font-medium text-gray-500 select-none">Attributes</dt>
        <div class="grid grid-cols-2 gap-x-4 gap-y-2 mt-1">
          <span v-for="atr in cs.item.attributesAtAddingTime" :key="atr.id" class="select-none inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800">
            <svg class="mr-1.5 h-2 w-2 text-purple-400" fill="currentColor" viewBox="0 0 8 8">
              <circle cx="4" cy="4" r="3" />
            </svg>
            {{atr.parent.name}}: {{atr.name}}
          </span>
        </div>
    </div>

    <div class="sm:col-span-2" v-if="cs.description.length>0">
        <dt class="text-sm font-medium text-gray-500 select-none">Description</dt>
        <dd class="mt-1 text-sm text-gray-900">{{cs.description}}</dd>
    </div>





    <div class="sm:col-span-2" v-if="allowEdit">
        <dt class="text-sm font-medium text-gray-500 select-none">Attachments</dt>
        <dd class="mt-1 text-sm text-gray-900">
        <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
            <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
            <div class="w-0 flex-1 flex items-center">
                <PaperClipIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span class="ml-2 flex-1 w-0 truncate"> resume_back_end_developer.pdf </span>
            </div>
            <div class="ml-4 flex-shrink-0">
                <a href="#" class="font-medium text-purple-600 hover:text-purple-500"> Download </a>
            </div>
            </li>
        </ul>
        </dd>
    </div>
    </dl>
</div>
</div>

</template>

<script>
    import { PaperClipIcon } from '@heroicons/vue/solid';
    //import axios from 'axios';
    //import {db,storage} from "@/firebase/gfbconf.js"
    export default {
        name: "ComplaintViewMode",
        components: {PaperClipIcon},
        data()
        {
            return {
                attendant: null
            }
        },
        props: ['complaint','allowEdit'],
        async created()
        {
            await this.getAttendant();
        },
        methods:
        {
            async getAttendant()
            {
                try{
                    const result = await this.complaint.assignedTo.ref.get();
                    if(result.exists)
                    {
                        this.attendant = result.data();
                    }
                    else
                    {
                        this.attendant = null;
                    }
                }
                catch(error)
                {
                    console.log(error);
                }
                
            }
        },
        computed:
        {
        }
    }
</script>
<style lang="scss" scoped>

</style>