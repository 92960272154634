<template>

  <nav aria-label="Progress" class="mt-4">
    <ol role="list" class="space-y-4 md:flex md:space-y-0 md:space-x-8 select-none">
      <li v-for="step in steps" :key="step.name" class="md:flex-1">
        <a v-if="step.status === 'complete'"  class="group pl-4 py-2 flex flex-col border-l-4 border-blue-200 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
          <span class="text-xs text-blue-500 font-semibold tracking-wide uppercase ">{{ step.id }}</span>
          <span class="text-sm font-medium">{{ step.name }}</span>
        </a>
        <a v-else-if="step.status === 'current'"  class="pl-4 py-2 flex flex-col border-l-4 border-blue-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step">
          <span class="text-xs text-blue-500 font-semibold tracking-wide uppercase">{{ step.id }}</span>
          <span class="text-sm font-medium">{{ step.name }}</span>
        </a>
        <a v-else class="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
          <span class="text-xs text-gray-500 font-semibold tracking-wide uppercase ">{{ step.id }}</span>
          <span class="text-sm font-medium">{{ step.name }}</span>
        </a>
      </li>
    </ol>
  </nav>

    <div class="flex sm:items-baseline sm:space-x-4 mt-4">
        <router-link to="/dashboard/complaints/pending" class="text-sm font-medium text-purple-600 hover:text-purple-500 block cursor-pointer"><span aria-hidden="true"> &larr;</span> Back to ticket list</router-link>
    </div>



  <Search :type="'clients'" :show="showSearchClient" @setThing="setClient" @toggle="toggleSearchClient"></Search>
  <Search :type="'products'" :show="showSearchProduct" @setThing="setProduct" @toggle="toggleSearchProducts"></Search>

  <!-- STEP 1 -->
  <form class="space-y-8 divide-y divide-gray-200" v-if="formStep === 1">
    <div class="space-y-8 divide-y divide-gray-200">
      <!-- Personal Info -->
      <div class="pt-4" id="personalInformation">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Personal Information</h3>
          <p class="mt-1 text-sm text-gray-500">Use search option to connect existing client credentials or type data manually.</p>
        </div>
        <div class="mt-4 mb-2 flex items-end w-full">
          <button type="button" @click="toggleSearchClient" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-purple-700 bg-purple-100 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Search for client </button>
        </div>


        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-2" >
            <!-- OLD VERSION -->
            <!-- <label for="location" class="block text-sm font-medium text-gray-700">Type</label>
            <select v-model="ComplaintTemplate.client.type" :class="{'mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md': true, 'bg-gray-100 pointer-events-none': clientFormDisabled}">
              <option>Client</option>
              <option>Company</option>
            </select> -->
            <Listbox as="div" v-model="ComplaintTemplate.client.type">
              <ListboxLabel class="block text-sm font-medium text-gray-700"> Type </ListboxLabel>
              <div class="mt-1 relative">
                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                  <span class="flex items-center truncate">{{ComplaintTemplate.client.type}}</span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                  <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                    <ListboxOption as="template" value="Client" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Client</span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                    <ListboxOption as="template" value="Company" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Company</span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>

          <div class="sm:col-span-4">
            <label for="email" class="block text-sm font-medium text-gray-700"> Name* </label>
            <div class="mt-1">
              <input v-model="ComplaintTemplate.client.name" type="text" autocomplete="name" :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md': true, 'border-gray-300': !errors.clientName, 'border-red-600': errors.clientName, 'bg-gray-100 pointer-events-none': clientFormDisabled }" />
              <span class="text-xs text-red-600" v-if="errors.clientName">Client name is empty.</span>
            </div>
          </div>


          
          <div class="sm:col-span-3">
            <label for="first-name" class="block text-sm font-medium text-gray-700"> E-mail* </label>
            <div class="mt-1">
              <input v-model="ComplaintTemplate.client.email" type="email" autocomplete="email" :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md': true, 'border-gray-300': !errors.email, 'border-red-600': errors.email, 'bg-gray-100 pointer-events-none': clientFormDisabled }" />
              <span class="text-xs text-red-600" v-if="errors.email">Email is empty or incorrect.</span>
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="last-name" class="block text-sm font-medium text-gray-700"> Phone Number <span class="text-xs opacity-70">(Optional)</span> </label>
            <div class="mt-1">
              <input type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43 || event.charCode === 45' v-model="ComplaintTemplate.client.phoneNum" autocomplete="tel" :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm border-gray-300 rounded-md': true, 'bg-gray-100 pointer-events-none': clientFormDisabled}" />
            </div>
          </div>


          <div class="sm:col-span-6">
            <label for="street-address" class="block text-sm font-medium text-gray-700"> Street address <span class="text-xs opacity-70">(Optional)</span></label>
            <div class="mt-1">
              <input type="text" v-model="ComplaintTemplate.client.address" autocomplete="street-address" :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md': true, 'border-gray-300': !errors.streetAddress, 'border-red-600': errors.streetAddress, 'bg-gray-100 pointer-events-none': clientFormDisabled }" />
              <span class="text-xs text-red-600" v-if="errors.streetAddress">Street address is empty.</span>
            </div>
          </div>

          <div class="sm:col-span-2">
            <label for="city" class="block text-sm font-medium text-gray-700"> City <span class="text-xs opacity-70">(Optional)</span> </label>
            <div class="mt-1">
              <input type="text" v-model="ComplaintTemplate.client.city"  autocomplete="address-level2" :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md': true, 'border-gray-300': !errors.city, 'border-red-600': errors.city, 'bg-gray-100 pointer-events-none': clientFormDisabled }" />
              <span class="text-xs text-red-600" v-if="errors.city">City field is empty.</span>
            </div>
          </div>


          <div class="sm:col-span-2">
            <label for="postal-code" class="block text-sm font-medium text-gray-700"> ZIP / Postal code <span class="text-xs opacity-70">(Optional)</span> </label>
            <div class="mt-1">
              <input type="text" v-model="ComplaintTemplate.client.postCode"  autocomplete="postal-code" :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md': true, 'border-gray-300': !errors.postCode, 'border-red-600': errors.postCode, 'bg-gray-100 pointer-events-none': clientFormDisabled }" />
              <span class="text-xs text-red-600" v-if="errors.postCode">Post code is empty.</span>
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="country" class="block text-sm font-medium text-gray-700"> Country <span class="text-xs opacity-70">(Optional)</span> </label>
            <div class="mt-1">
              <input type="text" v-model="ComplaintTemplate.client.country" autocomplete="country-name" :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md': true, 'border-gray-300': !errors.country, 'border-red-600': errors.country, 'bg-gray-100 pointer-events-none': clientFormDisabled }" />
              <span class="text-xs text-red-600" v-if="errors.country">Country field is empty.</span>
            </div>
          </div>

        </div>

        <div v-if="ComplaintTemplate.client.id.length>0" class="mt-4">
            <span class="text-xs text-red-500 select-none"> This complaint will be attached to existing client from database.</span>
            <span class="text-xs ml-2 text-purple-500 hover:text-purple-700 select-none cursor-pointer underline" @click="ComplaintTemplate.client.id = ''; clientFormDisabled=false ">Undo</span>
        </div>

        <div v-if="ComplaintTemplate.client.id.length === 0" class="mt-4">
            <span class="text-xs text-gray-500 select-none"> New client will be created based on provided data. Use client search option to change it.</span>
        </div>


        
      </div>
      <!-- Priority -->
      <div class="pt-4" >
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Priority and ticket attendant</h3>
          <p class="mt-1 text-sm text-gray-500">Choose ticket attendant and priority of complaint.</p>
        </div>
        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">

          <div>
            <!-- OLD VERSION -->
            <!-- <label  class="block text-sm font-medium text-gray-700">Assigned to</label>
            <select v-model="selectedAttendant" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
              <option v-for="person in attendants" :value="person.id" :key="person.id">{{person.displayName}}</option>
            </select> -->
            <Listbox as="div" v-model="selectedAttendant">
              <ListboxLabel class="block text-sm font-medium text-gray-700"> Assigned to </ListboxLabel>
              <div class="mt-1 relative">
                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                  <span class="flex items-center truncate">{{translateAttendant(selectedAttendant)}}</span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                  <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                    <ListboxOption as="template" v-for="person in attendants" :value="person.id" :key="person.id" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{person.displayName}}</span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>

          <div>
            <!-- OLD VERSION -->
            <!-- <label class="block text-sm font-medium text-gray-700">Priority</label>
            <select v-model="selectedPriority" @change="selectPriority" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
              <option v-for="priority in priorityList" :value="priority.name" :key="priority.name">{{priority.name}}</option>
            </select> -->
            <Listbox as="div" v-model="selectedPriority">
              <ListboxLabel class="block text-sm font-medium text-gray-700"> Priority </ListboxLabel>
              <div class="mt-1 relative">
                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                  <span class="flex items-center truncate">{{selectedPriority}}</span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                  <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                    <ListboxOption @click.prevent="selectPriority" as="template" v-for="priority in priorityList" :value="priority.name" :key="priority.name" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{priority.name}}</span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>
        </div>
      </div>
      <!-- Date -->
      <div class="pt-4" id="endDate">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Scheduled finalization date</h3>
          <p class="mt-1 text-sm text-gray-500">Date when the complaint should be ready.</p>
        </div>
        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">

          <div>
            <input type="date" v-model="selectedEndDate" :min="minEndDate" :max="maxEndDate"  :class="{'border-gray-300': !errors.endDate, 'border-red-600': errors.endDate, 'focus:outline-none text-base focus:ring-purple-500 focus:border-purple-500 rounded-md': true}">  
          </div>
          

        </div>
        <span class="text-xs text-red-600" v-if="errors.endDate">Finalization date is empty or incorrect. Date should be in range between {{minEndDate}} and {{maxEndDate}}.</span>

        <div class="text-xs mt-4">Suggested:</div>
        <div>
          <span class="text-purple-500 text-xs cursor-pointer hover:text-purple-700" @click="setEndDate(0,'days')">{{calculateDate(0,'days')}} (Same day)</span>
          <span class="text-xs text-gray-500 select-none"> // </span>
          <span class="text-purple-500 text-xs cursor-pointer hover:text-purple-700" @click="setEndDate(1,'days')">{{calculateDate(1,'days')}} (1 day)</span>
          <span class="text-xs text-gray-500 select-none"> // </span>
          <span class="text-purple-500 text-xs cursor-pointer hover:text-purple-700" @click="setEndDate(5,'days')">{{calculateDate(5,'days')}} (5 days)</span>
          <span class="text-xs text-gray-500 select-none"> // </span>
          <span class="text-purple-500 text-xs cursor-pointer hover:text-purple-700" @click="setEndDate(1,'week')">{{calculateDate(1,'week')}} (1 week)</span>
          <span class="text-xs text-gray-500 select-none"> // </span>
          <span class="text-purple-500 text-xs cursor-pointer hover:text-purple-700" @click="setEndDate(2,'week')">{{calculateDate(2,'week')}} (2 weeks)</span>
          <span class="text-xs text-gray-500 select-none"> // </span>
          <span class="text-purple-500 text-xs cursor-pointer hover:text-purple-700" @click="setEndDate(1,'month')">{{calculateDate(1,'month')}} (1 month)</span>
        </div>
      
        
      
      </div>

    </div>

    <div class="pt-5">
      <div class="flex flex-col sm:flex-row justify-end">
        <button @click.prevent="validateStep1" class="sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Next step: External identifiers</button>
      </div>
    </div>
  </form>



  <!-- STEP 2 -->
  <form class="space-y-8 divide-y divide-gray-200" v-if="formStep === 2">
    <div class="space-y-8 divide-y divide-gray-200">
      
      <!-- Order Identification -->
      <div class="pt-4" id="orderIdentification">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Order Identification</h3>
          <p class="mt-1 text-sm text-gray-500">Add identification numbers to easily find this complaint.</p>
        </div>
        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-4">
            <label for="email" class="block text-sm font-medium text-gray-700"> External Order Number <span class="text-xs opacity-70">(Optional)</span> </label>
            <div class="mt-1">
              <input type="text" v-model="ComplaintTemplate.externalIds.orderNum" :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md': true, 'border-gray-300': true, 'border-red-600': false }" />
              <span class="text-xs text-red-600" v-if="false">Error.</span>
            </div>
          </div>

          <div class="sm:col-span-4">
            <label for="email" class="block text-sm font-medium text-gray-700"> External Client Number <span class="text-xs opacity-70">(Optional)</span> </label>
            <div class="mt-1">
              <input type="text" v-model="ComplaintTemplate.externalIds.clientNum" :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md': true, 'border-gray-300': true, 'border-red-600': false }" />
              <span class="text-xs text-red-600" v-if="false">Error.</span>
            </div>
          </div>

          <div class="sm:col-span-4">
            <label for="email" class="block text-sm font-medium text-gray-700"> Invoice Number <span class="text-xs opacity-70">(Optional)</span> </label>
            <div class="mt-1">
              <input type="text" v-model="ComplaintTemplate.externalIds.invoiceNum" :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md': true, 'border-gray-300': true, 'border-red-600': false }" />
              <span class="text-xs text-red-600" v-if="false">Error.</span>
            </div>
          </div>


          


        </div>

        
      </div>

    </div>

    <div class="pt-5">
      <div class="flex flex-col-reverse sm:flex-row justify-end">
        <button type="button" @click.prevent="previousStep" class="bg-white mt-2 sm:mt-0 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Previous step</button>
        <button @click.prevent="nextStepWithoutVerification" class="sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Next step: Cases</button>
      </div>
    </div>
  </form>

  <!-- STEP 3 -->
  <form class="space-y-8 divide-y divide-gray-200" v-if="formStep === 3">
    <div class="space-y-8 divide-y divide-gray-200">
      
      <!-- Cases -->
      <div class="pt-4" id="productCases">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Cases</h3>
          <p class="mt-1 text-sm text-gray-500">Add specific product case.</p>
        </div>

        <EmptyState class="mt-4" v-if="ComplaintTemplate.cases.length === 0"></EmptyState>

        <!-- Cases V-for -->
        <div class="space-y-6 mt-4" v-for="(cs,indx) in ComplaintTemplate.cases" :key="cs.id" :id="cs.id">

          <div :class="{'bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6': true, 'border-2 border-red-500': cs.meta.haveError}">
            
            <div class="rounded-md bg-red-50 p-4 mb-4" v-if="cs.meta.haveError">
              <div class="flex">
                <div class="flex-shrink-0">
                  <!-- Heroicon name: solid/x-circle -->
                  <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-red-800">Found {{cs.meta.errorMsgs.length}} error(s) with your case submission.</h3>
                  <div class="mt-2 text-sm text-red-700">
                    <ul role="list" class="list-disc pl-5 space-y-1">
                      <li v-for="(msg,mIndx) in cs.meta.errorMsgs" :key="mIndx">{{msg}}</li>
                    </ul>
                  </div>
                </div>
              </div>
          </div>
            
            
            
            <div class="lg:grid lg:grid-cols-3 lg:gap-6">
              <div class="lg:col-span-1">
                <h3 :class="{'text-lg font-medium leading-6 text-gray-900': true, 'text-gray-900': ComplaintTemplate.cases[indx].acceptance === 'accepted', 'text-red-700': ComplaintTemplate.cases[indx].acceptance === 'declined'}">Case #{{indx+1}} <span class="text-xs text-yellow-700" v-show="!ComplaintTemplate.cases[indx].completed">(In progress)</span><span class="text-xs text-green-700" v-show="ComplaintTemplate.cases[indx].completed">(Completed)</span></h3>
                <label  class="block text-sm font-medium text-gray-700 mt-2">Product name <span class="text-xs text-gray-500 font-normal">(Provided by client)</span></label>
                <div class="mb-4 text-xs" v-if="ComplaintTemplate.cases[indx].meta.productNameProvidedByClient !== undefined">
                  {{ComplaintTemplate.cases[indx].meta.productNameProvidedByClient.substring(0,30)}}<span v-if="ComplaintTemplate.cases[indx].meta.productNameProvidedByClient.length>30">...</span>
                </div>                
                <label  class="block text-sm font-medium text-gray-700 mt-2">Selected product*</label>
                <div class="mt-4" v-if="ComplaintTemplate.cases[indx].item.id.length === 0">
                  <button type="button" @click="toggleSearchProducts(indx)" class="relative block w-full border-2 border-purple-200 border-dashed rounded-lg p-12 text-center hover:border-purple-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                    <div class="flex justify-center"><SearchIcon class="h-6 w-6 text-purple-300"></SearchIcon></div>
                    <span class="mt-2 block text-sm text-purple-400" > Click here to select product.. </span>
                  </button>
                </div>
                <div v-if="ComplaintTemplate.cases[indx].item.id.length > 0">
                  <div>
                    <div class="flex items-center">
                        <div class="h-28 w-28 flex-shrink-0 border-2 flex justify-center items-center border-gray-100 p-1 mt-2 select-none">
                          <img class="h-28 w-28 rounded-md object-center object-scale-down" :src="ComplaintTemplate.cases[indx].item.imageAtAddingTime.length>0 ? ComplaintTemplate.cases[indx].item.imageAtAddingTime : `https://avatars.dicebear.com/api/initials/${encodeURIComponent(`Product${ComplaintTemplate.cases[indx].item.id}`)}.svg`"  />
                        </div>
                        <div class="ml-2 select-none">
                          <div>{{ComplaintTemplate.cases[indx].item.name}}</div>
                          <div class="text-xs text-gray-500" v-if="ComplaintTemplate.cases[indx].acceptance === 'accepted'">Complaint cost: {{ComplaintTemplate.cases[indx].item.complaintCostAtAddingTime}} {{ComplaintTemplate.cases[indx].item.currencyAtAddingTime}}</div>
                          <button @click="toggleSearchProducts(indx)" type="button" class="mt-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Change</button>
                        </div>
                    </div>
                  </div>
                  <div class="mt-2">
                    <div class="text-xs font-medium text-gray-700 select-none">Attributes</div>
                    <div class="grid grid-cols-2 gap-x-4 gap-y-2 mt-1">
                      <span v-if="ComplaintTemplate.cases[indx].item.attributesAtAddingTime.length === 0" class="text-xs text-gray-400 select-none">No attributes</span>
                      <span v-for="atr in ComplaintTemplate.cases[indx].item.attributesAtAddingTime" :key="atr.id" class="select-none inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800">
                        <svg class="mr-1.5 h-2 w-2 text-purple-400" fill="currentColor" viewBox="0 0 8 8">
                          <circle cx="4" cy="4" r="3" />
                        </svg>
                        {{atr.parent.name}}: {{atr.name}}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- <p class="mt-1 text-sm text-gray-500">Use a permanent address where you can receive mail.</p> -->


              </div>
              <div class="mt-5 lg:mt-0 lg:col-span-2">
                <form>
                  <div class="grid grid-cols-6 gap-5">

                    <div class="col-span-6 sm:col-span-6 select-none">
                      <!-- OLD VERSION -->
                      <!-- <label class="block text-sm font-medium text-gray-700">Acceptance*</label>
                      <select v-model="ComplaintTemplate.cases[indx].acceptance" @change="correctDataWhenAcceptanceChanged(ComplaintTemplate.cases[indx])" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                        <option value="accepted">Accepted</option>
                        <option value="declined">Declined</option>
                      </select> -->
                      <Listbox as="div" v-model="ComplaintTemplate.cases[indx].acceptance">
                        <ListboxLabel class="block text-sm font-medium text-gray-700"> Acceptance* </ListboxLabel>
                        <div class="mt-1 relative">
                          <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                            <span class="flex items-center truncate">{{`${ComplaintTemplate.cases[indx].acceptance[0].toUpperCase()}${ComplaintTemplate.cases[indx].acceptance.slice(1)}`}}</span>
                            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                              <ListboxOption @click.prevent="correctDataWhenAcceptanceChanged(ComplaintTemplate.cases[indx]), checkActions()" as="template" value="accepted" v-slot="{ active, selected }">
                                <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Accepted</span>

                                  <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                              <ListboxOption @click.prevent="correctDataWhenAcceptanceChanged(ComplaintTemplate.cases[indx]), checkActions()" as="template" value="declined" v-slot="{ active, selected }">
                                <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Declined</span>

                                  <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>
                    </div>

                    <div :class="{'col-span-6 select-none': true, 'sm:col-span-3': ComplaintTemplate.cases[indx].acceptance === 'accepted', 'sm:col-span-6': ComplaintTemplate.cases[indx].acceptance === 'declined'}">
                      <!-- OLD VERSION -->
                      <!-- <label class="block text-sm font-medium text-gray-700">Reason*</label>
                      <select v-model="ComplaintTemplate.cases[indx].reason" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                        <option v-for="(reason,rsnIndx) in ComplaintTemplate.cases[indx].acceptance === 'accepted' ? reasonsList : reasonsDenyList" :key="rsnIndx" :value="reason.name">{{reason.name}}</option>
                      </select> -->
                      <Listbox as="div" v-model="ComplaintTemplate.cases[indx].reason">
                        <ListboxLabel class="block text-sm font-medium text-gray-700"> Reason* </ListboxLabel>
                        <div class="mt-1 relative">
                          <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                            <span class="flex items-center truncate">{{ComplaintTemplate.cases[indx].reason}}</span>
                            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                              <ListboxOption as="template" v-for="(reason,rsnIndx) in ComplaintTemplate.cases[indx].acceptance === 'accepted' ? reasonsList : reasonsDenyList" :key="rsnIndx" :value="reason.name" v-slot="{ active, selected }">
                                <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{reason.name}}</span>

                                  <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>
                    </div>

                    <div class="col-span-6 sm:col-span-3 select-none" v-if="ComplaintTemplate.cases[indx].acceptance === 'accepted'">
                      <!-- OLD VERSION -->
                      <!-- <label  class="block text-sm font-medium text-gray-700">Action*</label>
                      <select v-model="ComplaintTemplate.cases[indx].action" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                        <option v-for="(action,actIndx) in actionsList" :key="actIndx" :value="action.name">{{action.name}}</option>
                      </select> -->
                      <Listbox as="div" v-model="ComplaintTemplate.cases[indx].action">
                        <ListboxLabel class="block text-sm font-medium text-gray-700"> Action* </ListboxLabel>
                        <div class="mt-1 relative">
                          <ListboxButton  class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                            <span class="flex items-center truncate">{{ComplaintTemplate.cases[indx].action}}</span>
                            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                              <ListboxOption as="template" v-for="(action,actIndx) in actionsList" :key="actIndx" :value="action.name" v-slot="{ active, selected }"  @click="checkActions()">
                                <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{action.name}}</span>

                                  <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>
                    </div>

                    <div class="col-span-6 select-none" >
                      <!-- OLD VERSION -->
                      <!-- <label  class="block text-sm font-medium text-gray-700">Action preferred by client</label>
                      <select v-model="ComplaintTemplate.cases[indx].preferredActionByClient" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                        <option v-for="(action,actIndx) in actionsPreferredByClientList" :key="actIndx" :value="action.name">{{action.name}}</option>
                      </select> -->
                      <Listbox as="div" v-model="ComplaintTemplate.cases[indx].preferredActionByClient">
                        <ListboxLabel class="block text-sm font-medium text-gray-700"> Action preferred by client </ListboxLabel>
                        <div class="mt-1 relative">
                          <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                            <span class="flex items-center truncate">{{ComplaintTemplate.cases[indx].preferredActionByClient}}</span>
                            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                              <ListboxOption as="template" v-for="(action,actIndx) in actionsPreferredByClientList" :key="actIndx" :value="action.name" v-slot="{ active, selected }">
                                <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{action.name}}</span>

                                  <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>
                    </div>

                  </div>

                  <div class="mt-4">
                    <label for="comment" class="block text-sm font-medium text-gray-700">Description</label>
                    <div class="mt-1">
                      <textarea rows="4" v-model="ComplaintTemplate.cases[indx].description" class="shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm border-gray-300 rounded-md" />
                    </div>
                  </div>


                  <div class="mt-2">
                    <label for="comment" class="block text-sm font-medium text-gray-700 mb-2 select-none">Images</label>
                    <div class="flex items-center mb-2" v-if="imgHolder[ComplaintTemplate.cases[indx].id].length + countAttached(onlineForm.products[indx].files.images) === 0">
                      <PhotographIcon class="h-4 w-4 mr-1 opacity-40" aria-hidden="true" />
                      <span class="text-xs opacity-40">Image file not selected..</span>
                      <span class="text-xs ml-4 text-purple-500 underline hover:text-purple-700 cursor-pointer" @click.prevent="triggerImgUploaderClick(`imgUpldr${indx}`, this.imgQty - imgHolder[ComplaintTemplate.cases[indx].id].length - countAttached(onlineForm.products[indx].files.images))">Attach</span>
                    </div>
                    <div v-for="img in ComplaintTemplate.cases[indx].meta.filesOriginallyUploadedByClient.images" :key="img.url">
                      <div v-if="img.attach" class="flex items-center mb-2">
                      <PhotographIcon class="h-4 w-4 mr-1 opacity-60" aria-hidden="true" />
                      <span class="text-xs truncate opacity-60">{{img.originalName.substring(0,100)}}</span> 
                      <a class="text-xs ml-4 text-purple-500 underline hover:text-purple-700 cursor-pointer" :href="img.url" target="_blank">Open</a>
                      <div class="text-xs ml-4 text-red-500 underline hover:text-red-700 cursor-pointer" @click.prevent="img.attach = false" v-if="img.attach">Do not attach</div>
                      <div class="text-xs ml-4 text-blue-500 underline hover:text-blue-700 cursor-pointer" @click.prevent="img.attach = true" v-if="!img.attach && this.imgQty - imgHolder[ComplaintTemplate.cases[indx].id].length - countAttached(onlineForm.products[indx].files.images) > 0">Attach</div>                   
                      </div>
                    </div>
                    <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 mb-2">
                      <li v-for="(fl,flNdx) in imgHolder[ComplaintTemplate.cases[indx].id]" :key="flNdx" class="relative ">
                        <div class="flex w-20 h-20 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-purple-500 overflow-hidden">
                          <img :src="fl.dataURL" alt="Complaint processes" class="object-cover pointer-events-none " />
                        </div>
                        <p class="mt-2 block text-xs font-medium text-gray-900 truncate pointer-events-none">{{fl.file.name}}</p>
                        <p class="block text-xs font-medium text-gray-500 pointer-events-none">{{(fl.file.size/1024/1024).toFixed(2)}} MB</p>
                        <span class="text-xs text-purple-500 hover:opacity-75 select-none cursor-pointer" @click="imgDelete(imgHolder[ComplaintTemplate.cases[indx].id],flNdx)">Delete</span>
                      </li>
                    </ul>
                    <label for="comment" class="block text-xs font-medium text-gray-700 mb-2 select-none" v-if="ComplaintTemplate.cases[indx].meta.filesOriginallyUploadedByClient.images.length > 0">Uploaded by client <span @click="attachAllImages(indx)" class="text-xxs transition ease-in-out duration-300 cursor-pointer ml-2 bg-blue-100 py-0.5 px-2 rounded-lg text-blue-700 hover:bg-blue-200" v-if="this.imgQty - imgHolder[ComplaintTemplate.cases[indx].id].length - countAttached(onlineForm.products[indx].files.images) >= onlineForm.products[indx].files.images.length">Attach all</span></label>
                    <div v-for="img in ComplaintTemplate.cases[indx].meta.filesOriginallyUploadedByClient.images" :key="img.url">
                      <div class="flex items-center mb-2">
                      <PhotographIcon class="h-4 w-4 mr-1 opacity-60" aria-hidden="true" />
                      <span class="text-xs truncate opacity-60">{{img.originalName.substring(0,100)}}</span> 
                      <a class="text-xs ml-4 text-purple-500 underline hover:text-purple-700 cursor-pointer" v-if="!img.attach" :href="img.url" target="_blank">Open</a>
                      <div class="text-xxs ml-4 bg-green-100 py-0.5 px-2 rounded-lg text-green-700" v-if="img.attach">Attached</div>
                      <div class="text-xs ml-4 text-blue-500 underline hover:text-blue-700 cursor-pointer" @click.prevent="img.attach = true" v-if="!img.attach && this.imgQty - imgHolder[ComplaintTemplate.cases[indx].id].length - countAttached(onlineForm.products[indx].files.images) > 0">Attach</div>                   
                      </div>
                    </div>
                    <EmptyState class="mb-2" v-if="imgHolder[ComplaintTemplate.cases[indx].id].length === 0 && ComplaintTemplate.cases[indx].meta.filesOriginallyUploadedByClient.images.length === 0"></EmptyState>
                  </div>

                  <div class="mt-6 select-none mb-2" v-if="transform(this.$store.state.userSubscription.items[0].price.product.metadata.pdfUpload)">
                    <label for="comment" class="block text-sm font-medium text-gray-700 mb-2 ">PDF</label>
                    <div class="flex items-center" v-if="pdfHolder[ComplaintTemplate.cases[indx].id].length + countAttached(onlineForm.products[indx].files.pdfs) === 0">
                      <DocumentIcon class="h-4 w-4 mr-1 opacity-40" aria-hidden="true" />
                      <span class="text-xs opacity-40">PDF file not selected..</span>
                      <span class="text-xs ml-4 text-purple-500 underline hover:text-purple-700 cursor-pointer" @click.prevent="triggerPDFUploaderClick(`pdfUpldr${indx}`, pdfHolder[ComplaintTemplate.cases[indx].id].length + countAttached(onlineForm.products[indx].files.pdfs))">Attach</span>
                    </div>
                    <div v-for="pdf in ComplaintTemplate.cases[indx].meta.filesOriginallyUploadedByClient.pdfs" :key="pdf.url">
                      <div v-if="pdf.attach" class="flex items-center mb-2">
                      <DocumentIcon class="h-4 w-4 mr-1 opacity-60" aria-hidden="true" />
                      <span class="text-xs truncate opacity-60">{{pdf.originalName.substring(0,100)}}</span> 
                      <a class="text-xs ml-4 text-purple-500 underline hover:text-purple-700 cursor-pointer" :href="pdf.url" target="_blank">Open</a>
                      <div class="text-xs ml-4 text-red-500 underline hover:text-red-700 cursor-pointer" @click.prevent="pdf.attach = false" v-if="pdf.attach">Do not attach</div>
                      <div class="text-xs ml-4 text-blue-500 underline hover:text-blue-700 cursor-pointer" @click.prevent="pdf.attach = true" v-if="!pdf.attach && 1 - pdfHolder[ComplaintTemplate.cases[indx].id].length - countAttached(onlineForm.products[indx].files.pdfs) > 0">Attach</div>                   
                      </div>
                    </div>
                    <div class="flex items-center" v-for="(pdf,pdfIndx) in pdfHolder[ComplaintTemplate.cases[indx].id]" :key="pdfIndx">
                      <DocumentIcon class="h-4 w-4 mr-1 opacity-60" aria-hidden="true" />
                      <span class="text-xs opacity-60">{{pdf.file.name.substring(0,100)}}</span>
                      <span class="text-xs ml-4 text-purple-500 underline hover:text-purple-700 cursor-pointer" @click.prevent="pdfHolder[ComplaintTemplate.cases[indx].id].splice(pdfIndx,1)">Delete</span>
                    </div>
                    <label for="comment" class="mt-4 block text-xs font-medium text-gray-700 mb-2 select-none" v-if="ComplaintTemplate.cases[indx].meta.filesOriginallyUploadedByClient.pdfs.length > 0">Uploaded by client</label>
                    <div v-for="pdf in ComplaintTemplate.cases[indx].meta.filesOriginallyUploadedByClient.pdfs" :key="pdf.url">
                      <div class="flex items-center mb-2">
                      <DocumentIcon class="h-4 w-4 mr-1 opacity-60" aria-hidden="true" />
                      <span class="text-xs truncate opacity-60">{{pdf.originalName.substring(0,100)}}</span> 
                      <a class="text-xs ml-4 text-purple-500 underline hover:text-purple-700 cursor-pointer" v-if="!pdf.attach" :href="pdf.url" target="_blank">Open</a>
                      <div class="text-xxs ml-4 bg-green-100 py-0.5 px-2 rounded-lg text-green-700" v-if="pdf.attach">Attached</div>
                      <div class="text-xs ml-4 text-blue-500 underline hover:text-blue-700 cursor-pointer" @click.prevent="pdf.attach = true" v-if="!pdf.attach && 1 - pdfHolder[ComplaintTemplate.cases[indx].id].length - countAttached(onlineForm.products[indx].files.pdfs) > 0">Attach</div>                   
                      </div>
                    </div>                  
                  
                  </div>

                  <div class="flex flex-col sm:flex-row md:flex-col xl:flex-row justify-end">
                    <input type="file" accept="image/*" v-show="false" :ref="`imgUpldr${indx}`" @change="addToImgHolder($event,ComplaintTemplate.cases[indx].id,indx)">
                    <input type="file" accept="application/pdf" v-show="false" :ref="`pdfUpldr${indx}`" @change="addToPDFHolder($event,ComplaintTemplate.cases[indx].id)">  
                    <button type="button" v-if="transform(this.$store.state.userSubscription.items[0].price.product.metadata.pdfUpload)" @click.prevent="triggerPDFUploaderClick(`pdfUpldr${indx}`)" :class="{'mt-2 sm:mr-2 md:mr-0 xl:mr-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-blue-700 hover:bg-blue-200 bg-blue-100 focus:ring-blue-500': true, 'opacity-30 pointer-events-none select-none' : pdfHolder[ComplaintTemplate.cases[indx].id].length + countAttached(onlineForm.products[indx].files.pdfs) > 0}"><DocumentIcon class="h-4 w-4 mr-1" aria-hidden="true" />Attach PDF</button>
                    <button type="button" @click.prevent="triggerImgUploaderClick(`imgUpldr${indx}`, this.imgQty - imgHolder[ComplaintTemplate.cases[indx].id].length - countAttached(onlineForm.products[indx].files.images))" :class="{'mt-2 sm:mr-2 md:mr-0 xl:mr-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-blue-700 hover:bg-blue-200 bg-blue-100 focus:ring-blue-500': true, 'opacity-30 pointer-events-none' : this.imgQty - imgHolder[ComplaintTemplate.cases[indx].id].length - countAttached(onlineForm.products[indx].files.images) <= 0}"><CameraIcon class="h-4 w-4 mr-1" aria-hidden="true" />Add image <span v-if="this.imgQty - imgHolder[ComplaintTemplate.cases[indx].id].length - countAttached(onlineForm.products[indx].files.images) > 0">({{this.imgQty - imgHolder[ComplaintTemplate.cases[indx].id].length - countAttached(onlineForm.products[indx].files.images)}} left)</span></button>
                    <button @click.prevent="ComplaintTemplate.cases[indx].completed = !ComplaintTemplate.cases[indx].completed" type="button" :class="{'mt-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mr-6 md:mr-0 xl:mr-6': !ComplaintTemplate.cases[indx].completed, 'mt-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:mr-6 md:mr-0 xl:mr-6': ComplaintTemplate.cases[indx].completed}"><span v-show="ComplaintTemplate.cases[indx].completed">Mark as in progress</span><span v-show="!ComplaintTemplate.cases[indx].completed">Mark as completed</span></button>
                    <button @click.prevent="deleteCase(indx)" type="button" class="mt-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Delete case</button>
                  </div>


                </form>
              </div>
            </div>
          </div>

        </div>







        
      </div>

    </div>

    <div class="pt-5">
      <div class="flex justify-end mb-2 select-none" v-if="ComplaintTemplate.cases.length === 0"><span class="text-xs text-gray-400">Create at least one case to continue..</span></div>
      <div class="flex flex-col-reverse sm:flex-row justify-end">
        <button type="button" @click.prevent="previousStep" class="bg-white mt-2 sm:mt-0 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Previous step</button>
        <button @click.prevent="verifyCases" :class="{'sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2': true, 'focus:ring-purple-500 bg-purple-600 hover:bg-purple-700': ComplaintTemplate.cases.length > 0, 'bg-gray-400 hover:bg-gray-400 focus:ring-gray-500': ComplaintTemplate.cases.length === 0}">Next step: Delivery and chargeback</button>
      </div>
    </div>
    
  </form>

  <form class="space-y-8 divide-y divide-gray-200" v-if="formStep === 4">
    <div class="space-y-8 divide-y divide-gray-200">
      
      <!-- Delivery -->
      <div class="pt-4" id="deliveryOptions">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Item delivery / pickup <span class="text-xs opacity-70">(If needed)</span></h3>
          <p class="mt-1 text-sm text-gray-500">Indicate the place of delivery or pickup.</p>
        </div>
        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

          <div class="col-span-1 sm:col-span-3 select-none">
            <!-- OLD VERSION -->
            <!-- <label class="block text-sm font-medium text-gray-700">Delivery Type</label>
            <select v-model="ComplaintTemplate.delivery.type" @change="checkIfDeliveryClearNeeded"  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
              <option value="Not applicable">Not applicable</option>
              <option value="Delivery and pickup">Delivery and pickup</option>
              <option value="Delivery">Delivery</option>
              <option value="Pickup">Pickup</option>
            </select> -->
            <Listbox as="div" v-model="ComplaintTemplate.delivery.type">
              <ListboxLabel class="block text-sm font-medium text-gray-700"> Delivery Type </ListboxLabel>
              <div class="mt-1 relative">
                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                  <span class="flex items-center truncate">{{ComplaintTemplate.delivery.type}}</span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                  <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                    <ListboxOption @click.prevent="checkIfDeliveryClearNeeded" as="template" value="Not applicable" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Not applicable</span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                    <ListboxOption @click.prevent="checkIfDeliveryClearNeeded" as="template" value="Delivery and pickup" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Delivery and pickup</span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                    <ListboxOption @click.prevent="checkIfDeliveryClearNeeded" as="template" value="Delivery" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Delivery</span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                    <ListboxOption @click.prevent="checkIfDeliveryClearNeeded" as="template" value="Pickup" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Pickup</span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>

          <div class="sm:col-span-3" v-show="ComplaintTemplate.delivery.type !== 'Not applicable'">
            <label for="last-name" class="block text-sm font-medium text-gray-700"> Delivery provider </label>
            <div class="mt-1">
              <input type="text" v-model="ComplaintTemplate.delivery.courier"  class="shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:col-span-4" v-show="ComplaintTemplate.delivery.type !== 'Not applicable'">
            <label for="email" class="block text-sm font-medium text-gray-700"> Name </label>
            <div class="mt-1">
              <input v-model="ComplaintTemplate.delivery.name" type="text"  :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md': true, 'border-gray-300': !errors.clientName, 'border-red-600': errors.clientName }" />
              <span class="text-xs text-red-600" v-if="false"></span>
            </div>
          </div>

          <div class="sm:col-span-3" v-show="ComplaintTemplate.delivery.type !== 'Not applicable'">
            <label for="last-name" class="block text-sm font-medium text-gray-700"> Phone Number </label>
            <div class="mt-1">
              <input type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43 || event.charCode === 45' v-model="ComplaintTemplate.delivery.phoneNum"  class="shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm border-gray-300 rounded-md" />
            </div>
          </div>


          <div class="sm:col-span-6" v-show="ComplaintTemplate.delivery.type !== 'Not applicable'">
            <label for="street-address" class="block text-sm font-medium text-gray-700"> Street address </label>
            <div class="mt-1">
              <input type="text" v-model="ComplaintTemplate.delivery.address"  :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md': true, 'border-gray-300': !errors.streetAddress, 'border-red-600': errors.streetAddress }" />
              <span class="text-xs text-red-600" v-if="false"></span>
            </div>
          </div>

          <div class="sm:col-span-2" v-show="ComplaintTemplate.delivery.type !== 'Not applicable'">
            <label for="city" class="block text-sm font-medium text-gray-700"> City </label>
            <div class="mt-1">
              <input type="text" v-model="ComplaintTemplate.delivery.city" :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md': true, 'border-gray-300': !errors.city, 'border-red-600': errors.city }" />
              <span class="text-xs text-red-600" v-if="false"></span>
            </div>
          </div>


          <div class="sm:col-span-2" v-show="ComplaintTemplate.delivery.type !== 'Not applicable'">
            <label for="postal-code" class="block text-sm font-medium text-gray-700"> ZIP / Postal code </label>
            <div class="mt-1">
              <input type="text" v-model="ComplaintTemplate.delivery.postCode" :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md': true, 'border-gray-300': !errors.postCode, 'border-red-600': errors.postCode }" />
              <span class="text-xs text-red-600" v-if="false"></span>
            </div>
          </div>

          <div class="sm:col-span-3" v-show="ComplaintTemplate.delivery.type !== 'Not applicable'">
            <label for="country" class="block text-sm font-medium text-gray-700"> Country </label>
            <div class="mt-1">
              <input type="text" v-model="ComplaintTemplate.delivery.country" :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md': true, 'border-gray-300': !errors.country, 'border-red-600': errors.country }" />
              <span class="text-xs text-red-600" v-if="false"></span>
            </div>
          </div>

        </div>
        
        <button type="button" @click.prevent="copyDeliveryDataFromClient" v-show="ComplaintTemplate.delivery.type !== 'Not applicable'" :class="{'mt-4 mr-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-blue-700 hover:bg-blue-200 bg-blue-100 focus:ring-blue-500': true}"><ClipboardCopyIcon class="h-4 w-4 mr-1" aria-hidden="true" />Copy from client</button>
          


        
      </div>

      <div class="pt-4" id="deliveryOptions">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Refund transfer details <span class="text-xs opacity-70">{{moneyReturn ? '(Required)' : '(If needed)'}}</span></h3>
          <p class="mt-1 text-sm text-gray-500">Client chargeback information.</p>
        </div>
        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

          <div class="col-span-6 sm:col-span-3 select-none">
            <!-- OLD VERSION -->
            <!-- <label class="block text-sm font-medium text-gray-700">Applicable</label>
            <select v-model="ComplaintTemplate.chargeback.applicable" @change="checkIfChargebackNotActiveAndReset" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
              <option :value="true">Yes</option>
              <option :value="false">No</option>
            </select> -->
            <Listbox as="div" v-model="ComplaintTemplate.chargeback.applicable">
              <ListboxLabel class="block text-sm font-medium text-gray-700"> Applicable </ListboxLabel>
              <div class="mt-1 relative">
                <ListboxButton :disabled="moneyReturn" :class="[moneyReturn ? 'bg-gray-100 cursor-not-allowed' : 'bg-white']" class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                  <span class="flex items-center truncate">{{ComplaintTemplate.chargeback.applicable ? "Yes" : "No"}}</span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                  <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                    <ListboxOption @click.prevent="checkIfChargebackNotActiveAndReset" as="template" :value="true" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Yes</span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                    <ListboxOption @click.prevent="checkIfChargebackNotActiveAndReset" as="template" :value="false" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">No</span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
              <div v-if="moneyReturn" class="max-w-max text-xs mt-3 text-yellow-500 hover:text-yellow-600 px-2 flex bg-yellow-100 hover:bg-yellow-200 p-1 rounded-lg">
                  <svg aria-hidden="true" class="w-4 h-4 text-yellow-500 dark:text-yellow-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                  <span style="margin-left: 0.15rem;">We've disabled it because you have a money return selected on some cases</span>
              </div>
          </div>

        </div>

        <div class="mt-4" v-show="ComplaintTemplate.chargeback.applicable">
          <fieldset>
            <legend class="block text-sm font-medium text-gray-700">Transfer Details</legend>
            <div class="mt-1 bg-white rounded-md shadow-sm -space-y-px">
              <div>
                <label class="sr-only">IBAN</label>
                <input type="text" class="focus:ring-purple-500 focus:border-purple-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 text-sm border-gray-300" placeholder="IBAN" v-model="ComplaintTemplate.chargeback.iban" />
              </div>

              <div class="flex -space-x-px">
                <div class="w-1/2 flex-1 min-w-0">
                  <label class="sr-only">Recipient</label>
                  <input type="text" v-model="ComplaintTemplate.chargeback.recipient"  class="focus:ring-purple-500 focus:border-purple-500 relative block w-full rounded-none bg-transparent focus:z-10 text-sm border-gray-300" placeholder="Recipient" />
                </div>
                <div class="flex-1 min-w-0">
                  <label class="sr-only">SWIFT</label>
                  <input type="text" v-model="ComplaintTemplate.chargeback.swift"  class="focus:ring-purple-500 focus:border-purple-500 relative block w-full rounded-none  bg-transparent focus:z-10 text-sm border-gray-300" placeholder="SWIFT" />
                </div>
              </div>
              <div>
                <label  class="sr-only">Full Address</label>
                <input type="text" v-model="ComplaintTemplate.chargeback.address" class="focus:ring-purple-500 focus:border-purple-500 relative block w-full rounded-none  bg-transparent focus:z-10 text-sm border-gray-300" placeholder="Full address" />
              </div>
              <div>
                <label  class="sr-only">Transfer title</label>
                <input type="text" v-model="ComplaintTemplate.chargeback.transferTitle" class="focus:ring-purple-500 focus:border-purple-500 relative block w-full rounded-none  bg-transparent rounded-b-md focus:z-10 text-sm border-gray-300" placeholder="Transfer title" />
              </div>

            </div>
          </fieldset>

          <div class="mt-2">
            <label for="price" class="block text-sm font-medium text-gray-700">Amount</label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 text-sm"> $ </span>
              </div>
              <input type="number" min="0" @change="repairTransferAmount" v-model="ComplaintTemplate.chargeback.amount" class="focus:ring-purple-500 focus:border-purple-500 block w-full pl-7 pr-12 text-sm border-gray-300 rounded-md" placeholder="0.00" />

            </div>
          </div>

        </div>
        
          


        
      </div>

    </div>

    <div class="pt-5">
      <div class="flex flex-col-reverse sm:flex-row justify-end">
        <button type="button" @click.prevent="previousStep" class="bg-white mt-2 sm:mt-0 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Previous step</button>
        <button @click.prevent="toSummary" class="sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Next step: Summary</button>
      </div>
    </div>
  </form>

  <div v-if="formStep === 5">
    <ComplaintViewMode  :complaint="ComplaintTemplate" :allowEdit="false"></ComplaintViewMode>

    <div class="pt-5">
      <div class="flex flex-col-reverse sm:flex-row justify-end">
        <button type="button" @click.prevent="previousStep" class="bg-white mt-2 sm:mt-0 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Previous step</button>
        <button @click.prevent="addComplaint" class="sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Save</button>
      </div>
    </div>
  </div>




</template>

<script>
    import Search from "@/components/SearchInComplaintForm.vue";
    import ComplaintViewMode from "@/components/ComplaintViewMode.vue";
    import axios from 'axios';
    import moment from 'moment';
    import {db,storage,firebase} from '@/firebase/gfbconf';
    import EmptyState from '../components/EmptyState.vue';
    import { SearchIcon,CameraIcon, DocumentIcon, ClipboardCopyIcon,PhotographIcon } from '@heroicons/vue/outline'
    import { Listbox, ListboxButton, ListboxOption, ListboxOptions, ListboxLabel } from '@headlessui/vue'
    import generateApiKey from 'generate-api-key';
    import { CheckIcon, SelectorIcon} from '@heroicons/vue/solid'

    export default { 
        name: "ReviewTicket",
        components: {
          PhotographIcon,
          Search, 
          EmptyState,
          ComplaintViewMode,
          SearchIcon,
          CameraIcon,
          DocumentIcon,
          ClipboardCopyIcon,
          Listbox,
          ListboxButton,
          ListboxOption,
          ListboxOptions,
          ListboxLabel,
          CheckIcon,
          SelectorIcon
        },
        data()
        {
            return {
              formId: null,
              onlineForm: null,
              clientFormDisabled: false,
              moneyReturn: false,
              imgHolder: {},
              pdfHolder: {},
              caseEditingIndex: 0,
              formStep: 1,
              reasonsDenyList: [],
              reasonsList: [],
              priorityList: [],
              actionsList: [],
              actionsPreferredByClientList: [],
              errors: {
                clientName: false,
                email: false,
                streetAddress: false,
                city: false,
                postCode: false,
                country: false,
                endDate: false
              },
              ComplaintTemplate: {
                completed: false,
                id: "",
                priority: "",
                externalIds: {
                  orderNum: "",
                  clientNum: "",
                  invoiceNum: ""
                },
                meta: {
                },
                cases: [],
                assignedTo: {}, 
                client: {
                  type: "Client",
                  name: "",
                  email: "",
                  phoneNum: "",
                  address: "",
                  city: "",
                  postCode: "",
                  country: "",
                  description: "",
                  id: "",
                  meta: {
                    originallyCreatedBy: "",
                    dataUpdatedWithSearch: false,
                  }
                },
                delivery: {
                  done: false,
                  type: "Not applicable",
                  courier: "",
                  name: "",
                  phoneNum: "",
                  address: "",
                  city: "",
                  postCode: "",
                  country: "",
                },
                chargeback: {
                  applicable: false,
                  done: false,
                  iban: "",
                  swift: "",
                  recipient: "",
                  address: "",
                  transferTitle: "",
                  amount: 0,
                  currency: "USD"
                }
              },
              selectedAttendant: null,
              selectedPriority: null,
              selectedEndDate: null,
              maxEndDate: null,
              minEndDate: null,
              attendants: [],
              showSearchClient: false,
              showSearchProduct: false,
              steps: [{name: "Client credentials", status: "current", id: "Step 1"},{name: "External identifiers", status: "", id: "Step 2"},{name: "Product cases", status: "", id: "Step 3"}, {name: "Bank transfer & delivery", status: "", id: "Step 4"},{name: "Summary", status: "", id: "Step 5"}],
              // Img upload settings
              perImgSizeMB: 4,
              imgQty: 4,
              // Pdf uplaod settings
              perPDFSizeMB: 2,
            }
        },
        methods:
        {
            translateAttendant(attendantId){
              for(let i=0;i < this.attendants.length;i++)
              {
                if(this.attendants[i].id === attendantId)
                {
                  return this.attendants[i].displayName
                }
              }
            },
            attachAllImages(indx){
              for(let i=0; i< this.ComplaintTemplate.cases[indx].meta.filesOriginallyUploadedByClient.images.length; i++){
                let current = this.ComplaintTemplate.cases[indx].meta.filesOriginallyUploadedByClient.images[i]
                if(current.attach === false){
                  current.attach = true
                }
              }
            },
            async analytics(){
              const today = {
                year: moment().year(),
                // year: 2024,
                month: moment().format('MMMM'),
                // month: "December",
                day: parseInt(moment().format('D'))
                // day: 31
              }

              // catching data from form
              let dataToIncrement = {
                createdComplaint: 0,
                createdComplaintManually: 0,
                finishedComplaints: 0,
                activeComplaints: 0,
                declinedItems: 0,
                amountOfCases: 0,
                amountOfProducts: 0,
                valueOfComplainedProducts: 0,
                amountOfChargebacks: 0,
                valueOfChargebacks: 0,
                delivery: 0,
                deliveryPickup: 0,
                pickup: 0,
                level1: 0,
                level2: 0,
                level3: 0,
                level4: 0,
                level5: 0,
                products: []
              }

              //cases check
              let completedCases = 0
              let totalValueOfProducts = 0
              let declinedItems = 0
              for(let i=0; i< this.ComplaintTemplate.cases.length; i++){
                let currentCase = this.ComplaintTemplate.cases[i]
                if(currentCase.completed === true){
                  completedCases = completedCases + 1
                }
                if(currentCase.acceptance === "accepted"){
                totalValueOfProducts = totalValueOfProducts + currentCase.item.sellPriceAtAddingTime

                let product = {
                  amount: 1,
                  cost: currentCase.item.complaintCostAtAddingTime,
                  id: currentCase.item.id,
                  name: currentCase.item.name
                }
                let preparedProduct = {
                  id: currentCase.item.id,
                  name: currentCase.item.name,
                  costs: [{
                    amount: 1,
                    cost: currentCase.item.complaintCostAtAddingTime
                  }]
                }
                if(i === 0){
                  dataToIncrement.products.push(preparedProduct)
                }else{
                  let alreadyExists = false
                  let existingIndex = null
                  for(let y=0; y< dataToIncrement.products.length; y++){
                    let currentProduct = dataToIncrement.products[y]
                    if(currentProduct.id === product.id){
                      alreadyExists = true
                      existingIndex = y
                    }
                  }
                  if(alreadyExists === true){
                    let costExists = false
                    for(let i = 0; i < dataToIncrement.products[existingIndex].costs.length; i++) {
                      if(product.cost === dataToIncrement.products[existingIndex].costs[i].cost) {
                        costExists = i
                      }
                    }
                    if(costExists === false) {
                     dataToIncrement.products[existingIndex].costs.push({
                        amount: product.amount,
                        cost: product.cost,
                      })
                    } else {
                     dataToIncrement.products[existingIndex].costs[costExists].amount += product.amount
                    }
                  }else{
                    dataToIncrement.products.push(preparedProduct)
                  }
                }

                }
                if(currentCase.acceptance === "declined"){
                  declinedItems = declinedItems + 1
                }
              }

              dataToIncrement.createdComplaint = 1
              dataToIncrement.addedByClient = 1
              if(completedCases === this.ComplaintTemplate.cases.length){
                dataToIncrement.finishedComplaints = 1
              }else{
                dataToIncrement.activeComplaints = 1
              }
              dataToIncrement.declinedItems = declinedItems
              dataToIncrement.amountOfCases = this.ComplaintTemplate.cases.length
              dataToIncrement.amountOfProducts = this.ComplaintTemplate.cases.length
              dataToIncrement.valueOfComplainedProducts = totalValueOfProducts
              if(this.selectedPriority === 'Level 1'){dataToIncrement.level1 = 1}
              if(this.selectedPriority === 'Level 2'){dataToIncrement.level2 = 1}
              if(this.selectedPriority === 'Level 3'){dataToIncrement.level3 = 1}
              if(this.selectedPriority === 'Level 4'){dataToIncrement.level4 = 1}
              if(this.selectedPriority === 'Level 5'){dataToIncrement.level5 = 1}
              if(this.ComplaintTemplate.delivery.type === "Delivery and pickup"){dataToIncrement.deliveryPickup = 1}
              if(this.ComplaintTemplate.delivery.type === "Delivery"){dataToIncrement.delivery = 1}
              if(this.ComplaintTemplate.delivery.type === "Pickup"){dataToIncrement.pickup = 1}
              // products added in for in cases over data updating

              let currentGlobal = {
                complaints: {
                  active: dataToIncrement.activeComplaints,
                  addedByClient: dataToIncrement.addedByClient,
                  addedManually: 0,
                  casesAmount: dataToIncrement.amountOfCases,
                  chargeback: {
                    quantity: 0,
                    worth: 0
                  },
                  complainedProductsQuantity: dataToIncrement.amountOfProducts,
                  complaintsWorth: dataToIncrement.valueOfComplainedProducts,
                  created: dataToIncrement.createdComplaint,
                  declined: dataToIncrement.declinedItems,
                  deliveryData: {
                    delivery: dataToIncrement.delivery,
                    deliveryAndPickup: dataToIncrement.deliveryPickup,
                    pickup: dataToIncrement.pickup
                  },
                  finished: dataToIncrement.finishedComplaints,
                  priorityStats: {
                    Level1: dataToIncrement.level1,
                    Level2: dataToIncrement.level2,
                    Level3: dataToIncrement.level3,
                    Level4: dataToIncrement.level4,
                    Level5: dataToIncrement.level5,
                  }
                },
                productCreator: {
                  added: 0,
                  deleted: 0
                },
                products: dataToIncrement.products
              }

              if(this.ComplaintTemplate.chargeback.applicable) {
                currentGlobal.complaints.chargeback.quantity = 1
                currentGlobal.complaints.chargeback.worth = this.ComplaintTemplate.chargeback.amount
              }
              // updating/creating docs in firbase
              try{
              const result = await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Analytics").doc(today.year.toString()).get();
              //if doc of current year exists
              if(result.exists){
                let yearAnalytics = result.data()
                yearAnalytics.global.complaints.active = yearAnalytics.global.complaints.active + dataToIncrement.activeComplaints
                yearAnalytics.global.complaints.addedByClient = yearAnalytics.global.complaints.addedByClient + dataToIncrement.addedByClient
                yearAnalytics.global.complaints.casesAmount = yearAnalytics.global.complaints.casesAmount + dataToIncrement.amountOfCases
                yearAnalytics.global.complaints.chargeback.quantity += currentGlobal.complaints.chargeback.quantity
                yearAnalytics.global.complaints.chargeback.worth += currentGlobal.complaints.chargeback.worth
                yearAnalytics.global.complaints.complainedProductsQuantity = yearAnalytics.global.complaints.complainedProductsQuantity + dataToIncrement.amountOfProducts
                yearAnalytics.global.complaints.complaintsWorth = yearAnalytics.global.complaints.complaintsWorth + dataToIncrement.valueOfComplainedProducts
                yearAnalytics.global.complaints.created = yearAnalytics.global.complaints.created + dataToIncrement.createdComplaint
                yearAnalytics.global.complaints.declined = yearAnalytics.global.complaints.declined + dataToIncrement.declinedItems
                yearAnalytics.global.complaints.deliveryData.delivery = yearAnalytics.global.complaints.deliveryData.delivery + dataToIncrement.delivery
                yearAnalytics.global.complaints.deliveryData.deliveryAndPickup = yearAnalytics.global.complaints.deliveryData.deliveryAndPickup + dataToIncrement.deliveryPickup
                yearAnalytics.global.complaints.deliveryData.pickup = yearAnalytics.global.complaints.deliveryData.pickup + dataToIncrement.pickup
                yearAnalytics.global.complaints.finished = yearAnalytics.global.complaints.finished + dataToIncrement.finishedComplaints
                yearAnalytics.global.complaints.priorityStats.Level1 = yearAnalytics.global.complaints.priorityStats.Level1 + dataToIncrement.level1
                yearAnalytics.global.complaints.priorityStats.Level2 = yearAnalytics.global.complaints.priorityStats.Level2 + dataToIncrement.level2
                yearAnalytics.global.complaints.priorityStats.Level3 = yearAnalytics.global.complaints.priorityStats.Level3 + dataToIncrement.level3
                yearAnalytics.global.complaints.priorityStats.Level4 = yearAnalytics.global.complaints.priorityStats.Level4 + dataToIncrement.level4
                yearAnalytics.global.complaints.priorityStats.Level5 = yearAnalytics.global.complaints.priorityStats.Level5 + dataToIncrement.level5
                if(yearAnalytics.global.products.length === 0){
                  yearAnalytics.global.products = dataToIncrement.products
                }else{
                  for(let z=0; z< dataToIncrement.products.length; z++){
                    let currentProductToAdd = dataToIncrement.products[z]
                    let exist = false
                    let existIndex = null
                    for(let x=0; x< yearAnalytics.global.products.length; x++){
                      let currentDbProduct = yearAnalytics.global.products[x]
                      if(currentProductToAdd.id === currentDbProduct.id){
                        exist = true
                        existIndex = x
                      }
                    }
                    if(exist === true){
                      let costExists = false
                        for(let i = 0; i < yearAnalytics.global.products[existIndex].costs.length; i++) {
                          if(currentProductToAdd.costs[0].cost === yearAnalytics.global.products[existIndex].costs[i].cost) {
                            costExists = i
                          }
                        }
                        if(costExists === false) {
                          yearAnalytics.global.products[existIndex].costs.push({
                            amount: currentProductToAdd.costs[0].amount,
                            cost: currentProductToAdd.costs[0].cost,
                          })
                        } else {
                          yearAnalytics.global.products[existIndex].costs[costExists].amount += currentProductToAdd.costs[0].amount
                        }
                    }else{
                      yearAnalytics.global.products.push({
                        id: currentProductToAdd.id,
                        name: currentProductToAdd.name,
                        costs: [{
                          amount: currentProductToAdd.costs[0].amount,
                          cost: currentProductToAdd.costs[0].cost
                        }]
                      })
                    }
                  }
                }
                //if doc of current month in current year not exists
                if(yearAnalytics[today.month] === undefined){
                  yearAnalytics[today.month] = {
                    days: {
                    },
                    global: currentGlobal
                  }
                  yearAnalytics[today.month].days[today.day.toString()] = {
                    global: currentGlobal
                  }
                  //if doc of current month in current year exists
                }else{
                  yearAnalytics[today.month].global.complaints.active = yearAnalytics[today.month].global.complaints.active + dataToIncrement.activeComplaints
                  yearAnalytics[today.month].global.complaints.addedByClient = yearAnalytics[today.month].global.complaints.addedByClient + dataToIncrement.addedByClient
                  yearAnalytics[today.month].global.complaints.casesAmount = yearAnalytics[today.month].global.complaints.casesAmount + dataToIncrement.amountOfCases
                  yearAnalytics[today.month].global.complaints.chargeback.quantity += currentGlobal.complaints.chargeback.quantity
                  yearAnalytics[today.month].global.complaints.chargeback.worth += currentGlobal.complaints.chargeback.worth
                  yearAnalytics[today.month].global.complaints.complainedProductsQuantity = yearAnalytics[today.month].global.complaints.complainedProductsQuantity + dataToIncrement.amountOfProducts
                  yearAnalytics[today.month].global.complaints.complaintsWorth = yearAnalytics[today.month].global.complaints.complaintsWorth + dataToIncrement.valueOfComplainedProducts
                  yearAnalytics[today.month].global.complaints.created = yearAnalytics[today.month].global.complaints.created + dataToIncrement.createdComplaint
                  yearAnalytics[today.month].global.complaints.declined = yearAnalytics[today.month].global.complaints.declined + dataToIncrement.declinedItems
                  yearAnalytics[today.month].global.complaints.deliveryData.delivery = yearAnalytics[today.month].global.complaints.deliveryData.delivery + dataToIncrement.delivery
                  yearAnalytics[today.month].global.complaints.deliveryData.deliveryAndPickup = yearAnalytics[today.month].global.complaints.deliveryData.deliveryAndPickup + dataToIncrement.deliveryPickup
                  yearAnalytics[today.month].global.complaints.deliveryData.pickup = yearAnalytics[today.month].global.complaints.deliveryData.pickup + dataToIncrement.pickup
                  yearAnalytics[today.month].global.complaints.finished = yearAnalytics[today.month].global.complaints.finished + dataToIncrement.finishedComplaints
                  yearAnalytics[today.month].global.complaints.priorityStats.Level1 = yearAnalytics[today.month].global.complaints.priorityStats.Level1 + dataToIncrement.level1
                  yearAnalytics[today.month].global.complaints.priorityStats.Level2 = yearAnalytics[today.month].global.complaints.priorityStats.Level2 + dataToIncrement.level2
                  yearAnalytics[today.month].global.complaints.priorityStats.Level3 = yearAnalytics[today.month].global.complaints.priorityStats.Level3 + dataToIncrement.level3
                  yearAnalytics[today.month].global.complaints.priorityStats.Level4 = yearAnalytics[today.month].global.complaints.priorityStats.Level4 + dataToIncrement.level4
                  yearAnalytics[today.month].global.complaints.priorityStats.Level5 = yearAnalytics[today.month].global.complaints.priorityStats.Level5 + dataToIncrement.level5
                  if(yearAnalytics[today.month].global.products.length === 0){
                    yearAnalytics[today.month].global.products = dataToIncrement.products
                  }else{
                    for(let c=0; c< dataToIncrement.products.length; c++){
                      let currentProductToAdd = dataToIncrement.products[c]
                      let exist = false
                      let existIndex = null
                      for(let v=0; v < yearAnalytics[today.month].global.products.length; v++){
                        let currentDbProduct = yearAnalytics[today.month].global.products[v]
                        if(currentProductToAdd.id === currentDbProduct.id){
                          exist = true
                          existIndex = v
                        }
                      }
                      if(exist === true){
                      let costExists = false
                        for(let i = 0; i < yearAnalytics[today.month].global.products[existIndex].costs.length; i++) {
                          if(currentProductToAdd.costs[0].cost === yearAnalytics[today.month].global.products[existIndex].costs[i].cost) {
                            costExists = i
                          }
                        }
                        if(costExists === false) {
                          yearAnalytics[today.month].global.products[existIndex].costs.push({
                            amount: currentProductToAdd.costs[0].amount,
                            cost: currentProductToAdd.costs[0].cost,
                          })
                        } else {
                          yearAnalytics[today.month].global.products[existIndex].costs[costExists].amount += currentProductToAdd.costs[0].amount
                        }
                      }else{
                        yearAnalytics[today.month].global.products.push({
                          id: currentProductToAdd.id,
                          name: currentProductToAdd.name,
                          costs: [{
                            amount: currentProductToAdd.costs[0].amount,
                            cost: currentProductToAdd.costs[0].cost
                          }]
                        })
                      }
                    }

                  }
                  // day not exists
                  if(yearAnalytics[today.month].days[today.day.toString()] === undefined){
                    yearAnalytics[today.month].days[today.day.toString()] = {
                      global: currentGlobal
                    }
                  }else{
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.active = yearAnalytics[today.month].days[today.day.toString()].global.complaints.active + dataToIncrement.activeComplaints
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.addedByClient = yearAnalytics[today.month].days[today.day.toString()].global.complaints.addedByClient + dataToIncrement.addedByClient
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.casesAmount = yearAnalytics[today.month].days[today.day.toString()].global.complaints.casesAmount + dataToIncrement.amountOfCases
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.chargeback.quantity += currentGlobal.complaints.chargeback.quantity
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.complainedProductsQuantity = yearAnalytics[today.month].days[today.day.toString()].global.complaints.complainedProductsQuantity + dataToIncrement.amountOfProducts
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.chargeback.worth += currentGlobal.complaints.chargeback.worth
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.complaintsWorth = yearAnalytics[today.month].days[today.day.toString()].global.complaints.complaintsWorth + dataToIncrement.valueOfComplainedProducts
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.created = yearAnalytics[today.month].days[today.day.toString()].global.complaints.created + dataToIncrement.createdComplaint
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.declined = yearAnalytics[today.month].days[today.day.toString()].global.complaints.declined + dataToIncrement.declinedItems
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.deliveryData.delivery = yearAnalytics[today.month].days[today.day.toString()].global.complaints.deliveryData.delivery + dataToIncrement.delivery
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.deliveryData.deliveryAndPickup = yearAnalytics[today.month].days[today.day.toString()].global.complaints.deliveryData.deliveryAndPickup + dataToIncrement.deliveryPickup
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.deliveryData.pickup = yearAnalytics[today.month].days[today.day.toString()].global.complaints.deliveryData.pickup + dataToIncrement.pickup
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.finished = yearAnalytics[today.month].days[today.day.toString()].global.complaints.finished + dataToIncrement.finishedComplaints
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.priorityStats.Level1 = yearAnalytics[today.month].days[today.day.toString()].global.complaints.priorityStats.Level1 + dataToIncrement.level1
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.priorityStats.Level2 = yearAnalytics[today.month].days[today.day.toString()].global.complaints.priorityStats.Level2 + dataToIncrement.level2
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.priorityStats.Level3 = yearAnalytics[today.month].days[today.day.toString()].global.complaints.priorityStats.Level3 + dataToIncrement.level3
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.priorityStats.Level4 = yearAnalytics[today.month].days[today.day.toString()].global.complaints.priorityStats.Level4 + dataToIncrement.level4
                    yearAnalytics[today.month].days[today.day.toString()].global.complaints.priorityStats.Level5 = yearAnalytics[today.month].days[today.day.toString()].global.complaints.priorityStats.Level5 + dataToIncrement.level5
                    if(yearAnalytics[today.month].days[today.day.toString()].global.products.length === 0){
                      yearAnalytics[today.month].days[today.day.toString()].global.products = dataToIncrement.products
                    }else{
                      for(let b=0; b< dataToIncrement.products.length; b++){
                        let currentProductToAdd = dataToIncrement.products[b]
                        let exist = false
                        let existIndex = null
                        for(let n=0; n< yearAnalytics[today.month].days[today.day.toString()].global.products.length; n++){
                          let currentDbProduct = yearAnalytics[today.month].days[today.day.toString()].global.products[n]
                          if(currentProductToAdd.id === currentDbProduct.id){
                            exist = true
                            existIndex = n
                          }
                        }
                        if(exist === true){
                          let costExists = false
                          for(let i = 0; i < yearAnalytics[today.month].days[today.day.toString()].global.products[existIndex].costs.length; i++) {
                            if(currentProductToAdd.costs[0].cost === yearAnalytics[today.month].days[today.day.toString()].global.products[existIndex].costs[i].cost) {
                              costExists = i
                            }
                          }
                          if(costExists === false) {
                            yearAnalytics[today.month].days[today.day.toString()].global.products[existIndex].costs.push({
                              amount: currentProductToAdd.costs[0].amount,
                              cost: currentProductToAdd.costs[0].cost,
                            })
                          } else {
                            yearAnalytics[today.month].days[today.day.toString()].global.products[existIndex].costs[costExists].amount += currentProductToAdd.costs[0].amount
                          }

                        }else{
                          yearAnalytics[today.month].days[today.day.toString()].global.products.push({
                            id: currentProductToAdd.id,
                            name: currentProductToAdd.name,
                            costs: [{
                              amount: currentProductToAdd.costs[0].amount,
                              cost: currentProductToAdd.costs[0].cost
                            }]
                          })
                        }
                      }
                    }
                  }
                }
                await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Analytics").doc(today.year.toString()).set(yearAnalytics);
                //if doc of current year not exists
              }else{
                let yearAnalytics = {
                  global: currentGlobal,
                  year: today.year
                }
                yearAnalytics[today.month] = {
                    days: {
                    },
                    global: currentGlobal
                  }
                  yearAnalytics[today.month].days[today.day.toString()] = {
                    global: currentGlobal
                  }
                  await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Analytics").doc(today.year.toString()).set(yearAnalytics);
              }
              }catch(error)
              {
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Something went wrong!",
                    subheader: `Analytics update failed!`,
                    success: false
                });
                this.$router.push("/dashboard/complaints/list");
                }
            },
            transform(string) {
                return eval(string)
            },
            countAttached(ref)
            {
              let count = 0;
              for(let i=0; i<ref.length;i++)
              {
                if(ref[i].attach)
                {
                  count = count + 1;
                }
              }
              return count;
            },
            async makeCaseFromData(prod)
            {
               const caseTemplate = {
                completed: false,
                acceptance: 'accepted',
                id: generateApiKey({ method: 'string', pool: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", length: 128 }),
                item: {
                  type: "Product",
                  name: "",
                  ref: null,
                  model: "",
                  id: "",
                  ean: "",
                  sku: "",
                  attributesAtAddingTime: [],
                  currencyAtAddingTime: "USD",
                  imageAtAddingTime: "",
                  sellPriceAtAddingTime: 0,
                  productionCostAtAddingTime: 0,
                  complaintCostAtAddingTime: 0
                },
                files: {
                  images: [],
                  pdfs: [],
                  videos: [],
                  other: []
                },
                description: prod.problemDescription,
                action: prod.expectation.name === 'No information' ? 'No action' : prod.expectation.name,
                preferredActionByClient: prod.expectation.name,
                reason: "Other",
                meta: {
                  productNameProvidedByClient: prod.name,
                  filesOriginallyUploadedByClient: prod.files,
                  haveError: false,
                  errorMsgs: [],
                  changes: {
                    last: {
                      userId: this.$store.state.user.uid,
                      date: moment().toDate(),
                      info: "Creation"
                    },
                    list: [{
                      userId: this.$store.state.user.uid,
                      date: moment().toDate(),
                      info: "Creation"                      
                    }]
                  },
                  createdBy: {
                    username: this.$store.state.user.displayName,
                    uid: this.$store.state.user.uid,
                    date: moment().toDate()
                  }
                }
              }
              this.imgHolder[`${caseTemplate.id}`] = [];
              this.pdfHolder[`${caseTemplate.id}`] = [];
              const result = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/elastic/search/products", {
                  system: this.$store.state.userData.system.id,
                  phrase: prod.name
              }).catch((error)=>
              {
                  console.log(error);
              });
              
              if(result.data.status === 'ok')
              {
                if(result.data.result.length>0)
                {
                  let picked = result.data.result[0];
                  caseTemplate.item.name = picked.name;
                  caseTemplate.item.model = picked.model;
                  caseTemplate.item.id = picked.id;
                  caseTemplate.item.ean = picked.ean;
                  caseTemplate.item.ref = this.$store.state.userData.system.ref.collection("Products").doc(picked.id);
                  caseTemplate.item.sku = picked.sku;
                  caseTemplate.item.imageAtAddingTime = picked.image.url;
                  caseTemplate.item.sellPriceAtAddingTime = parseFloat(picked.pricesAndCosts.sellPrice);
                  caseTemplate.item.productionCostAtAddingTime = parseFloat(picked.pricesAndCosts.productionCost);
                  caseTemplate.item.complaintCostAtAddingTime = parseFloat(picked.pricesAndCosts.complaintCost);
                  caseTemplate.item.attributesAtAddingTime = picked.attribs.list;
                }
              }
              this.ComplaintTemplate.cases.push(caseTemplate);
            },
            async getFormById()
            {
              if(this.$route.query.id === undefined) this.$router.push("/dashboard/complaints/pending")
              if(this.$route.query.id.length === 0) this.$router.push("/dashboard/complaints/pending")
              try{
                const result = await this.$store.state.userData.system.ref.collection("ComplaintsBuffer").doc(this.$route.query.id.toString()).get();
                if(result.exists)
                {
                  // Integrate
                  this.onlineForm = result.data();
                  this.ComplaintTemplate.client.name = this.onlineForm.clientCredentials.name;
                  this.ComplaintTemplate.client.email = this.onlineForm.clientCredentials.email;
                  this.ComplaintTemplate.client.phoneNum = this.onlineForm.clientCredentials.phone;
                  this.ComplaintTemplate.client.address = this.onlineForm.clientCredentials.streetAddress;
                  this.ComplaintTemplate.client.city = this.onlineForm.clientCredentials.city;
                  this.ComplaintTemplate.client.postCode = this.onlineForm.clientCredentials.postCode;
                  this.ComplaintTemplate.client.country = this.onlineForm.clientCredentials.country;

                  // Delivery

                  if(this.onlineForm.delivery.city.length>0 || this.onlineForm.delivery.streetAddress.length>0 || this.onlineForm.delivery.postCode.length>0 || this.onlineForm.delivery.country.length>0)
                  {
                    this.ComplaintTemplate.delivery.type = "Delivery";
                    this.ComplaintTemplate.delivery.name = this.onlineForm.clientCredentials.name;
                    this.ComplaintTemplate.delivery.phoneNum = this.onlineForm.clientCredentials.phone;
                    this.ComplaintTemplate.delivery.city = this.onlineForm.delivery.city;
                    this.ComplaintTemplate.delivery.postCode = this.onlineForm.delivery.postCode;
                    this.ComplaintTemplate.delivery.address = this.onlineForm.delivery.streetAddress;
                    this.ComplaintTemplate.delivery.country = this.onlineForm.delivery.country;
                  }
                  

                  // Chargeback
                  if(this.onlineForm.clientCredentials.accountNumber !== undefined && this.onlineForm.clientCredentials.accountNumber !== null)
                  {
                    if(this.onlineForm.clientCredentials.accountNumber.length > 0)
                    {
                      this.ComplaintTemplate.chargeback.applicable = true;
                      this.ComplaintTemplate.chargeback.iban = this.onlineForm.clientCredentials.accountNumber;
                      this.ComplaintTemplate.chargeback.recipient = this.onlineForm.clientCredentials.name;
                      this.ComplaintTemplate.chargeback.address = `${this.onlineForm.clientCredentials.streetAddress} ${this.onlineForm.clientCredentials.city} ${this.onlineForm.clientCredentials.postCode} ${this.onlineForm.clientCredentials.country}`;
                      this.ComplaintTemplate.chargeback.transferTitle = "Chargeback"
                    }
                  }

      
                  for(let i=0; i<this.onlineForm.products.length;i++)
                  {
                    let cProd = this.onlineForm.products[i];
                    let orderNums = [];
                    let invoiceNums = [];
                    if(cProd.externalId.type === "Order number")
                    {
                      orderNums.push(cProd.externalId.value)
                    }
                    if(cProd.externalId.type === "Invoice number")
                    {
                      invoiceNums.push(cProd.externalId.value)
                    }
                    this.ComplaintTemplate.externalIds.orderNum = orderNums.toString();
                    this.ComplaintTemplate.externalIds.invoiceNum = invoiceNums.toString();
                    await this.makeCaseFromData(cProd);

                    // Mark images as unattached
                    for(let y=0; y < cProd.files.images.length; y++)
                    {
                      cProd.files.images[y].attach = false;
                    }

                    // Mark pdfs as unattached
                    for(let y=0; y < cProd.files.pdfs.length; y++)
                    {
                      cProd.files.pdfs[y].attach = false;
                    }


                  }
                }
                else
                {
                  this.$router.push("/dashboard/complaints/pending");
                }
              }
              catch(error)
              {
                console.log(error);
                this.$router.push("/dashboard/complaints/pending");
              }
              

            },
            correctDataWhenAcceptanceChanged(c)
            {
              if(c.acceptance === 'declined')
              {
                c.action = 'No action';
                c.reason = 'Other';
                c.completed = true;
              }
              if(c.acceptance === 'accepted')
              {
                c.reason = 'Other';
              }
            },
            async calculateId()
            {
                const newId = Math.floor(Math.random() * 100000000000)+1;
                const freeId = await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Complaints").doc(newId.toString()).get();
                return {
                    id: newId,
                    exists: freeId.exists
                }
            },
            async addNewClient()
            {
                let client = {
                    type: this.ComplaintTemplate.client.type,
                    name: this.ComplaintTemplate.client.name,
                    email: this.ComplaintTemplate.client.email,
                    phoneNum: this.ComplaintTemplate.client.phoneNum,
                    address: this.ComplaintTemplate.client.address,
                    city: this.ComplaintTemplate.client.city,
                    postCode: this.ComplaintTemplate.client.postCode,
                    description: "",
                    country: this.ComplaintTemplate.client.country,
                    editHistory: [],
                    complaints: [],
                    creationDate: moment().toDate(),
                    createdBy: this.$store.state.user.displayName
                }
                let preparedClientName = client.name.trim().split(/\s+/)
                preparedClientName[0] = preparedClientName[0] === undefined ? 'User' : preparedClientName[0]
                preparedClientName[1] = preparedClientName[1] === undefined ? '' : preparedClientName[1]
                client.photoUrl = `https://avatars.dicebear.com/api/initials/${encodeURIComponent(preparedClientName[0]+" "+preparedClientName[1])}.svg`
                
                const elasticResponse = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/elastic/add/client", {
                  doc: {
                    name: client.name,
                    email: client.email,
                    phoneNum: client.phoneNum
                  },
                  system: this.$store.state.userData.system.id
                }).catch((error)=>
                {
                  console.log(error);
                });

                this.$store.state.userData.system.ref.collection("Events").add(
                {
                  user: {
                    name: this.$store.state.user.displayName,
                    photo: this.$store.state.user.photoURL
                  },
                  creationDate: moment().toDate(),
                  eventName: `Added new client ${this.ComplaintTemplate.client.name}.`
                })

                if(elasticResponse.data.status === "ok")
                {
                  client.elasticSearch = elasticResponse.data.elasticSearch;
                }
                else
                {
                  client.elasticSearch = {
                    docId: "",
                    index: "",
                    creationDate: ""
                  };
                }

                const newClientResponse = await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Clients").add(client);
                db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Clients").doc(newClientResponse.id).update({
                  id: newClientResponse.id
                })

                return newClientResponse.id;
            },
            async addComplaint()
            {
              this.$store.commit('setGlobalLoader',{
                  show: true,
                  head: "Creating new complaint..",
                  subheader: `Do not close this tab.`,
              });
              // Add client
              try{
                // Calculate free id slot for complaint
                let freeId = "";
                for(let i=0; i < 999; i++)
                {
                    const resultOfGettingId = await this.calculateId();
                    if(!resultOfGettingId.exists)
                    {
                      freeId = resultOfGettingId.id;
                      break;
                    }
                }
                this.ComplaintTemplate.id = freeId;

                // Add client if not picked by search mechanism earlier
                if(this.ComplaintTemplate.client.id.length === 0)
                {
                  this.ComplaintTemplate.client.id = await this.addNewClient();
                  this.ComplaintTemplate.client.id = this.ComplaintTemplate.client.id.toString();
                }

                // Upload images
                for(let i=0; i< this.ComplaintTemplate.cases.length; i++)
                {
                  let cs = this.ComplaintTemplate.cases[i];
                  const filesArr = this.imgHolder[cs.id];
                  const pdfArr = this.pdfHolder[cs.id];
                  const storageRef = storage.ref();
                  for(let y=0; y < filesArr.length; y++)
                  {
                    const file = filesArr[y].file;
                    const filename = "img_" + cs.id.toString() + y.toString();
                    const imageRef = storageRef.child(`systems/${this.$store.state.userData.system.id}/complaints/${freeId}/${filename}`);
                    await imageRef.put(file);
                    const fileUrl = await imageRef.getDownloadURL();
                    cs.files.images.push({url: fileUrl, name: filename, path: `systems/${this.$store.state.userData.system.id}/complaints/${freeId}/${filename}`, type: file.type, size: file.size, originalName: file.name});
                  }

                  // Upload pdf
                  for(let y=0; y < pdfArr.length; y++)
                  {
                    const file = pdfArr[y].file;
                    const filename = "pdf_" + cs.id.toString() + y.toString();
                    const pdfRef = storageRef.child(`systems/${this.$store.state.userData.system.id}/complaints/${freeId}/${filename}`);
                    await pdfRef.put(file);
                    const fileUrl = await pdfRef.getDownloadURL();
                    cs.files.pdfs.push({url: fileUrl, name: filename, path: `systems/${this.$store.state.userData.system.id}/complaints/${freeId}/${filename}`, type: file.type, size: file.size, originalName: file.name});
                  }

                  // Copy images from online form
                  for(let y=0; y < cs.meta.filesOriginallyUploadedByClient.images.length; y++)
                  {
                    let _cImg = cs.meta.filesOriginallyUploadedByClient.images[y];
                    if(_cImg.attach)
                    {
                      cs.files.images.push({url: _cImg.url, name: _cImg.name, path: _cImg.path, type: _cImg.type, size: _cImg.size, originalName: _cImg.originalName});
                    }
                  }

                  // Copy pdfs from online form
                  for(let y=0; y < cs.meta.filesOriginallyUploadedByClient.pdfs.length; y++)
                  {
                    let _cPdf = cs.meta.filesOriginallyUploadedByClient.pdfs[y];
                    if(_cPdf.attach)
                    {
                      cs.files.pdfs.push({url: _cPdf.url, name: _cPdf.name, path: _cPdf.path, type: _cPdf.type, size: _cPdf.size, originalName: _cPdf.originalName});
                    }
                  }
                }


                this.$store.state.userData.system.ref.collection("Clients").doc(this.ComplaintTemplate.client.id).update({
                  'complaints': firebase.firestore.FieldValue.arrayUnion({
                    id: this.ComplaintTemplate.id.toString(),
                  })
                })

                const elasticResponse = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/elastic/add/complaint", {
                  doc: {
                    id: this.ComplaintTemplate.id.toString(),
                    clientName: this.ComplaintTemplate.client.name,
                    email: this.ComplaintTemplate.client.email,
                    phone: this.ComplaintTemplate.client.phoneNum,
                    externalOrderNum: this.ComplaintTemplate.externalIds.orderNum,
                    externalClientNum: this.ComplaintTemplate.externalIds.clientNum,
                    externalInvoiceNum: this.ComplaintTemplate.externalIds.invoiceNum
                  },
                  system: this.$store.state.userData.system.id
                });

                if(elasticResponse.data.status === "ok")
                {
                  this.ComplaintTemplate.elasticSearch = elasticResponse.data.elasticSearch;
                }
                else
                {
                  this.ComplaintTemplate.elasticSearch = {
                    docId: "",
                    index: "",
                    creationDate: ""
                  };
                }

                // Mark complaint as already completed
                let completedCases = 0;
                for(let y=0; y< this.ComplaintTemplate.cases.length; y++)
                {
                  let currentCase = this.ComplaintTemplate.cases[y];
                  if(currentCase.completed === true)
                  {
                    completedCases++;
                  }
                }

                this.ComplaintTemplate.portalData = {
                  completeDate: null,
                  formId: this.onlineForm.formId,
                  submitDate: this.ComplaintTemplate.createdDate.asText
                }

                if(completedCases === this.ComplaintTemplate.cases.length)
                {
                  this.ComplaintTemplate.completed = true;
                  this.ComplaintTemplate.portalData.completeDate = this.ComplaintTemplate.createdDate.asText;
                }

                //add analytics
                await this.analytics()

                await this.$store.state.userData.system.ref.collection("Complaints").doc(freeId.toString()).set(this.ComplaintTemplate);
                await this.$store.state.userData.system.ref.collection('ComplaintsBuffer').doc(this.onlineForm.formId.toString()).delete()
                
                this.$store.state.userData.system.ref.collection("Events").add(
                {
                  user: {
                    name: this.$store.state.user.displayName,
                    photo: this.$store.state.user.photoURL
                  },
                  creationDate: moment().toDate(),
                  eventName: `Added new complaint ${freeId.toString()}.`
                })

                const clientData = (await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Clients").doc(this.ComplaintTemplate.client.id).get()).data()
                this.$store.state.userData.system.ref.collection("Chats").doc(freeId.toString()).set({
                  attendantId: this.ComplaintTemplate.assignedTo.attendantId,
                  client: {
                    email: clientData.email,
                    id: clientData.id,
                    image: clientData.photoUrl,
                    name: clientData.name,
                    phoneNum: clientData.phoneNum
                  },
                  createdDate: moment().format('YYYY-MM-DD'),
                  customerMail: clientData.email,
                  id: freeId.toString(),
                  lastCustomerMessage: moment().toDate(),
                  messages: [],
                  read: true
                });

                this.$store.commit('setGlobalLoader',{
                    show: false,
                    head: "",
                    subheader: `Do not close this tab.`,
                });
                this.$router.push(`/dashboard/complaints/edit?id=${freeId.toString()}`);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Complaint created with success!",
                    subheader: `Your complaint is now ready.`,
                    success: true
                });  
              }
              catch(error)
              {
                console.log(error);
                this.$store.commit('setGlobalLoader',{
                    show: false,
                    head: "",
                    subheader: `Do not close this tab.`,
                });
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Complaint creation failed!",
                    subheader: `Try again.`,
                    success: false
                });
                this.$router.push("/dashboard/complaints/list");

              }

            },
            toSummary()
            {

              // Dates prepare
              this.ComplaintTemplate.scheduledEnd = {
                asText: this.selectedEndDate,
                unix: moment(this.selectedEndDate).unix(),
                dateObj: moment(this.selectedEndDate).toDate(),
                dayOfWeek: moment(this.selectedEndDate).day(),
                dayNum: moment(this.selectedEndDate).date(),
                monthNum: moment(this.selectedEndDate).month() + 1,
                year: moment(this.selectedEndDate).year(),
                weekNum: moment(this.selectedEndDate).week()
              };

              this.ComplaintTemplate.createdDate = {
                asText: moment().format("YYYY-MM-DD").toString(),
                unix: moment().unix(),
                dateObj: moment().toDate(),
                dayOfWeek: moment().day(),
                dayNum: moment().date(),
                monthNum: moment().month() + 1,
                year: moment().year(),
                weekNum: moment().week()
              };

              // Complaint assistant
              this.ComplaintTemplate.assignedTo = {
                attendantId: this.selectedAttendant,
                ref: db.collection("UsersData").doc(this.selectedAttendant)
              };

              let haveDeclinedProd = false;
              let haveAcceptedProd = false;
              let declinedCases = 0;
              for(let i=0; i<this.ComplaintTemplate.cases.length;i++)
              {
                if(this.ComplaintTemplate.cases[i].acceptance === 'declined')
                {
                  // Zeroed costs
                  this.ComplaintTemplate.cases[i].item.complaintCostAtAddingTime = 0;
                  this.ComplaintTemplate.cases[i].item.productionCostAtAddingTime = 0;
                  // Not ++ cause of linter
                  declinedCases = declinedCases + 1;
                  haveDeclinedProd = true;
                }
                if(this.ComplaintTemplate.cases[i].acceptance === 'accepted')
                {
                  haveAcceptedProd = true;
                }
              }

              this.ComplaintTemplate.meta =  {
                  acceptance: {
                    haveDeclinedProducts: haveDeclinedProd,
                    haveAcceptedProducts: haveAcceptedProd,
                    amounts: {
                      accepted: this.ComplaintTemplate.cases.length-declinedCases,
                      declined: declinedCases,
                    }
                  },
                  creatorName: this.$store.state.user.displayName,
                  creatorId: this.$store.state.user.uid,
                  creatorRef: db.collection("UsersData").doc(this.$store.state.user.uid),
                  via: "Online form",
                  systemRef: this.$store.state.userData.system.ref,
                  systemId: this.$store.state.userData.system.id,
                  lastUpdate: moment().toDate(),
                  changes: {
                    last: {
                      userId: this.$store.state.user.uid,
                      date: moment().toDate(),
                      info: "Creation"
                    },
                    list: [{
                      userId: this.$store.state.user.uid,
                      date: moment().toDate(),
                      info: "Creation"                      
                    }]
                  }
              };

              // Access to customer portal
              this.ComplaintTemplate.portalAccess = {
                allowed: true,
                email: this.ComplaintTemplate.client.email,
                autologKey: "",
                key: generateApiKey({ method: 'string', pool: "0123456789QWERTYUIOPASDFGHJKLZXCVBNM", length: 6 }),
              }


              // Key for link portal loading
              this.ComplaintTemplate.portalAccess.autologKey = generateApiKey({
                method: 'uuidv5',
                name: `cmpl_${this.$store.state.userData.system.id}_${this.ComplaintTemplate.portalAccess.key}`,
                namespace: '0f3819f3-b417-4c4c-b674-853473800265',
                prefix: 'complaia'
              });

              this.nextStepWithoutVerification();
            },
            selectPriority()
            {
              this.ComplaintTemplate.priority = this.selectedPriority;
            },
            repairTransferAmount()
            {
              if(typeof this.ComplaintTemplate.chargeback.amount !== "number")
              {
                this.ComplaintTemplate.chargeback.amount = 0;
              }
              if(this.ComplaintTemplate.chargeback.amount < 0)
              {
                this.ComplaintTemplate.chargeback.amount = 0;
              }
              if(this.ComplaintTemplate.chargeback.amount > 0)
              { 
                this.ComplaintTemplate.chargeback.amount = parseFloat(this.ComplaintTemplate.chargeback.amount);
              }
            },
            checkIfChargebackNotActiveAndReset()
            {
              if(!this.ComplaintTemplate.chargeback.applicable)
              {
                this.ComplaintTemplate.chargeback = {
                  applicable: false,
                  done: false,
                  iban: "",
                  swift: "",
                  recipient: "",
                  address: "",
                  currency: "USD",
                  transferTitle: "",
                  amount: 0,
                }  
              }
            },
            copyDeliveryDataFromClient()
            {
                this.ComplaintTemplate.delivery.name = this.ComplaintTemplate.client.name;
                this.ComplaintTemplate.delivery.phoneNum = this.ComplaintTemplate.client.phoneNum;
                this.ComplaintTemplate.delivery.address = this.ComplaintTemplate.client.address;
                this.ComplaintTemplate.delivery.city = this.ComplaintTemplate.client.city;
                this.ComplaintTemplate.delivery.postCode = this.ComplaintTemplate.client.postCode;
                this.ComplaintTemplate.delivery.country =  this.ComplaintTemplate.client.country;
            },
            checkIfDeliveryClearNeeded()
            {
              if(this.ComplaintTemplate.delivery.type === 'Not applicable')
              {
                this.ComplaintTemplate.delivery = {
                  done: false,
                  type: "Not applicable",
                  courier: "",
                  name: "",
                  phoneNum: "",
                  address: "",
                  city: "",
                  postCode: "",
                  country: "",
                }
              }
            },
            verifyCases()
            {
              if(this.ComplaintTemplate.cases.length===0) return;
              for(let i=0; i<this.ComplaintTemplate.cases.length; i++)
              {
                let _case = this.ComplaintTemplate.cases[i];
                _case.meta.haveError = false;
                _case.meta.errorMsgs = [];
                if(_case.item.ref === null)
                {
                  _case.meta.haveError = true;
                  _case.meta.errorMsgs.push("No product selected.");
                  const elmntToView = document.getElementById(_case.id);
                  elmntToView.scrollIntoView({behavior: "smooth"}); 
                }
              }
              let allowed = 0;
              for(let i=0; i<this.ComplaintTemplate.cases.length; i++)
              {
                let _case = this.ComplaintTemplate.cases[i];
                !_case.meta.haveError ? allowed++ : allowed+0;  
              }
              if(allowed === this.ComplaintTemplate.cases.length)
              {
                this.nextStepWithoutVerification();
              }
            },
            imgDelete(ref,flIndx)
            {
              ref.splice(flIndx,1);
              this.$store.commit('setNotification',{
                show: true,
                head: "Image has been deleted!",
                subheader: ``,
                success: true
              });
              //this.imgHolder[].splice(flNdx,1)
            },
            deleteCase(indx)
            {
              this.ComplaintTemplate.cases.splice(indx,1);
              this.$store.commit('setNotification',{
                show: true,
                head: "Case has been deleted!",
                subheader: ``,
                success: true
              }); 
            },
            addToPDFHolder(event,holderId)
            {
              this.pdfHolder[holderId] = [];
              if(event.target.files.length === 0) return;
              const file = event.target.files[0];
              const sizeInMb = file.size / 1024 / 1024;
              if(sizeInMb <= this.perPDFSizeMB)
              {
                this.pdfHolder[holderId].push({file: file});
                this.$store.commit('setNotification',{
                  show: true,
                  head: "PDF file has been added!",
                  subheader: ``,
                  success: true
                }); 
              }
              else
              {
                  this.$store.commit('setNotification',{
                    show: true,
                    head: "Cannot add this PDF!",
                    subheader: `File size is more than ${this.perPDFSizeMB} MB.`,
                    success: false
                  });                
              }
            },
            addToImgHolder(event,holderId,indx)
            {
              if(event.target.files.length === 0) return;
              const file = event.target.files[0];
              const sizeInMb = file.size / 1024 / 1024;
              if(sizeInMb <= this.perImgSizeMB)
              {
                let fileAlreadyAdded = false;
                for(let i=0;i<this.imgHolder[holderId].length;i++)
                {
                  if(this.imgHolder[holderId][i].file.name === file.name)
                  {
                    fileAlreadyAdded = true;
                  }
                }
                if(!fileAlreadyAdded)
                {
                  const reader = new FileReader();
                  reader.onload = (event) => {
                      const content = event.target.result;
                      this.imgHolder[holderId].push({file: file, dataURL: content});
                      this.$refs[`imgUpldr${indx}`][0].value = "";
                      this.$store.commit('setNotification',{
                        show: true,
                        head: "Image has been added!",
                        subheader: ``,
                        success: true
                      }); 
                  };
                  reader.readAsDataURL(file);
                }
                else
                {
                  this.$store.commit('setNotification',{
                    show: true,
                    head: "Cannot add this image!",
                    subheader: `List contains file with same name.`,
                    success: false
                  });                  
                }

                
                
              }
              else
              {
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Cannot add this image!",
                  subheader: `File size is more than ${this.perImgSizeMB} MB.`,
                  success: false
                });
              }

            },
            createNewCase()
            {
              const caseTemplate = {
                completed: false,
                acceptance: 'accepted',
                id: generateApiKey({ method: 'string', pool: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", length: 128 }),
                item: {
                  type: "Product",
                  name: "",
                  ref: null,
                  model: "",
                  id: "",
                  ean: "",
                  sku: "",
                  attributesAtAddingTime: [],
                  currencyAtAddingTime: "USD",
                  imageAtAddingTime: "",
                  sellPriceAtAddingTime: 0,
                  productionCostAtAddingTime: 0,
                  complaintCostAtAddingTime: 0
                },
                files: {
                  images: [],
                  pdfs: [],
                  videos: [],
                  other: []
                },
                description: "",
                action: "No action",
                preferredActionByClient: "No information",
                reason: "Other",
                meta: {
                  productNameProvidedByClient: undefined,
                  filesOriginallyUploadedByClient: [],
                  haveError: false,
                  errorMsgs: [],
                  changes: {
                    last: {
                      userId: this.$store.state.user.uid,
                      date: moment().toDate(),
                      info: "Creation"
                    },
                    list: [{
                      userId: this.$store.state.user.uid,
                      date: moment().toDate(),
                      info: "Creation"                      
                    }]
                  },
                  createdBy: {
                    username: this.$store.state.user.displayName,
                    uid: this.$store.state.user.uid,
                    date: moment().toDate()
                  }
                }
              }
              this.imgHolder[`${caseTemplate.id}`] = [];
              this.pdfHolder[`${caseTemplate.id}`] = [];
              this.ComplaintTemplate.cases.push(caseTemplate);
              // Scroll to new case
              setTimeout(()=>
              {
                const elmntToView = document.getElementById(caseTemplate.id);
                elmntToView.scrollIntoView({behavior: "smooth"}); 
              },250)

            },
            triggerPDFUploaderClick(uploaderRefAsText, amount)
            {
              console.log(amount);
              this.$refs[uploaderRefAsText][0].click();
            },
            triggerImgUploaderClick(uploaderRefAsText,left)
            {
              if(left === 0) return;
              this.$refs[uploaderRefAsText][0].click();
            },
            nextStepWithoutVerification()
            {
              this.steps[this.formStep-1].status = "complete";
              this.formStep++;
              this.steps[this.formStep-1].status = "current";
            },
            previousStep()
            {
              this.steps[this.formStep-1].status = "";
              this.formStep--;
              this.steps[this.formStep-1].status = "current";
            },
            setEndDate(amount,type)
            {
              this.selectedEndDate = moment().add(amount,type).format("YYYY-MM-DD").toString();
              this.errors.endDate = false;
            },
            calculateDate(amount,type)
            {
              return moment().add(amount,type).format("DD-MM-YYYY").toString();
            },
            setMaxAndMinEndDate()
            {
              this.minEndDate = moment().format("YYYY-MM-DD").toString();
              this.maxEndDate = moment().add("10", "years").format("YYYY-MM-DD").toString();
              this.selectedEndDate = moment().format("YYYY-MM-DD").toString();
            },
            validateStep1()
            {
              this.ComplaintTemplate.client.name.length === 0 ? this.errors.clientName = true : this.errors.clientName = false;
              this.ComplaintTemplate.client.email.length>0 && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.ComplaintTemplate.client.email) ? this.errors.email = false : this.errors.email = true;
              // this.ComplaintTemplate.client.address.length>0 ? this.errors.streetAddress = false : this.errors.streetAddress = true;
              // this.ComplaintTemplate.client.city.length>0 ? this.errors.city = false : this.errors.city = true;
              // this.ComplaintTemplate.client.postCode.length>0 ? this.errors.postCode = false : this.errors.postCode = true;
              // this.ComplaintTemplate.client.country.length>0 ? this.errors.country = false : this.errors.country = true;

              // First look at date
              this.selectedEndDate !== null && moment(this.selectedEndDate).isValid()  ? this.errors.endDate = false : this.errors.endDate = true;

              // Be sure with moment
              if(this.errors.endDate === false)
              {
                if(!moment(this.selectedEndDate).isBetween(this.minEndDate,this.maxEndDate, undefined, '[]'))
                {
                  this.errors.endDate = true;
                }
              }

              if(this.errors.clientName || this.errors.email || this.errors.streetAddress || this.errors.city || this.errors.postCode || this.errors.country)
              {
                const elmntToView = document.getElementById("personalInformation");
                elmntToView.scrollIntoView({behavior: "smooth"}); 
              }

              if(this.errors.endDate)
              {
                const elmntToView = document.getElementById("endDate");
                elmntToView.scrollIntoView({behavior: "smooth"}); 
              }

              if(!this.errors.clientName && !this.errors.email && !this.errors.streetAddress && !this.errors.city && !this.errors.postCode && !this.errors.country && !this.errors.endDate)
              {
                this.formStep++;
                this.steps[0].status = "complete";
                this.steps[1].status = "current";
              }
            },
            async getActions()
            {
              // Receive Actions
              const result = await db.collection("OptionValues").doc("ComplaintActions").get();
              if(result.exists)
              {
                this.actionsList = result.data().values;
              }
              const resultPrefByClient = await db.collection("OptionValues").doc("ExpectationsValues").get();
              if(resultPrefByClient.exists)
              {
                this.actionsPreferredByClientList = resultPrefByClient.data().values;
              }
            },
            async getReasons()
            {
              // Accepted
              const result = await db.collection("OptionValues").doc("ComplaintReasons").get();
              if(result.exists)
              {
                this.reasonsList = result.data().values;
              }
              // Denied
              const result2 = await db.collection("OptionValues").doc("ComplaintDenyReasons").get();
              if(result2.exists)
              {
                this.reasonsDenyList = result2.data().values;
              }
            },
            async getPriority()
            {
              // Get priority
              const result = await db.collection("OptionValues").doc("ComplaintPriority").get();
              if(result.exists)
              {
                this.priorityList = result.data().values;
                this.selectedPriority = result.data().values[0].name;
                this.ComplaintTemplate.priority = result.data().values[0].name;
              }
            },
            async getAttendants()
            {
              // Get attendants
              const result = await this.$store.state.userData.system.ref.get();
              if(result.exists)
              {
                this.attendants = result.data().users;
                this.selectedAttendant = result.data().users[0].id;
              }
            },
            getStep()
            {
              for(let i=0;i < this.steps.length;i++)
              {
                if(this.steps[i].status === 'current')
                {
                  return {name: this.steps[i].name, index: i}
                }
              }
            },
            toggleSearchProducts(caseEditingIndex = 0)
            {
              this.caseEditingIndex = caseEditingIndex;
              this.showSearchProduct = !this.showSearchProduct;
            },
            toggleSearchClient()
            {
              this.showSearchClient = !this.showSearchClient;
            },
            setProduct(thing)
            {
              if(this.ComplaintTemplate.cases[this.caseEditingIndex] === undefined) return;
              // clear to be sure - someone could just change product
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.name = "";
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.model = "";
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.id = "";
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.ean = "";
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.ref = null;
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.sku = "";
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.imageAtAddingTime = "";
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.sellPriceAtAddingTime = 0;
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.productionCostAtAddingTime = 0;
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.complaintCostAtAddingTime = 0;
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.attributesAtAddingTime = [];

              // save
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.name = thing.name;
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.model = thing.model;
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.id = thing.id;
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.ean = thing.ean;
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.ref = this.$store.state.userData.system.ref.collection("Products").doc(thing.id);
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.sku = thing.sku;
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.imageAtAddingTime = thing.image.url;
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.sellPriceAtAddingTime = parseFloat(thing.pricesAndCosts.sellPrice);
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.productionCostAtAddingTime = parseFloat(thing.pricesAndCosts.productionCost);
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.complaintCostAtAddingTime = parseFloat(thing.pricesAndCosts.complaintCost);
              this.ComplaintTemplate.cases[this.caseEditingIndex].item.attributesAtAddingTime = thing.attribs.list;
              // Repair references after stringify in attributes
              for(let y=0; y < this.ComplaintTemplate.cases[this.caseEditingIndex].item.attributesAtAddingTime.length; y++)
              {
                this.ComplaintTemplate.cases[this.caseEditingIndex].item.attributesAtAddingTime[y].ref = this.$store.state.userData.system.ref.collection("Attributes").doc(this.ComplaintTemplate.cases[this.caseEditingIndex].item.attributesAtAddingTime[y].id);
                this.ComplaintTemplate.cases[this.caseEditingIndex].item.attributesAtAddingTime[y].parent.ref = this.$store.state.userData.system.ref.collection("GroupsOfAttributes").doc(this.ComplaintTemplate.cases[this.caseEditingIndex].item.attributesAtAddingTime[y].parent.id);              
              }
              if(this.moneyReturn) {
                this.checkActions()
              }
              this.toggleSearchProducts();
            },
            setClient(thing)
            {
              this.toggleSearchClient();
              this.ComplaintTemplate.client.name = thing.name;
              this.ComplaintTemplate.client.type = thing.type;
              this.ComplaintTemplate.client.email = thing.email;
              this.ComplaintTemplate.client.phoneNum = thing.phoneNum;
              this.ComplaintTemplate.client.address = thing.address;
              this.ComplaintTemplate.client.city = thing.city;
              this.ComplaintTemplate.client.postCode = thing.postCode;
              this.ComplaintTemplate.client.country = thing.country;
              this.ComplaintTemplate.client.description = thing.description;
              this.ComplaintTemplate.client.id = thing.id;
              this.ComplaintTemplate.client.meta = {
                originallyCreatedBy: thing.createdBy,
                dataUpdatedWithSearch: true,
              };
              // Zeroed errors when setting client
              this.errors.clientName = false;
              this.errors.email = false;
              // Disable client inputs
              this.clientFormDisabled = true;


            },
            checkActions() {
              let valueOfChargebacks = 0;
              let moneyReturn = false;
              this.ComplaintTemplate.cases.forEach(item => {
                  if(item.action === 'Money return') {
                    moneyReturn = true
                    this.ComplaintTemplate.chargeback.applicable = true
                    valueOfChargebacks += item.item.sellPriceAtAddingTime
                  }
              });
              this.moneyReturn = moneyReturn
              this.ComplaintTemplate.chargeback.amount = valueOfChargebacks
              if(!moneyReturn) {
                this.ComplaintTemplate.chargeback.amount = 0
                this.ComplaintTemplate.chargeback.applicable = false
              }
            },
        },
        async created()
        {
          await this.getFormById();
          await this.setMaxAndMinEndDate();
          await this.getPriority();
          await this.getAttendants();
          await this.getReasons();
          await this.getActions();
          await this.checkActions();
          this.metadata = this.$store.state.userSubscription.items[0].price.product.metadata
          if(this.metadata.planName === "starter" || this.metadata.planName === "pro" || this.metadata.planName === "standard") {
            this.perImgSizeMB = parseInt(this.metadata.imageSizePerFileMb)
            this.imgQty = parseInt(this.metadata.imagesPerComplaint)
           }

        },
        computed:
        {
            
        }
    }
</script>
<style lang="scss" scoped>

</style>