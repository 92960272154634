<template>
    <div>
            <AttribValuesList v-if="modal.open" :product="modal.product" @close="closeModal"></AttribValuesList>
        
        <div class="flex sm:items-baseline sm:space-x-4 mt-2 mb-2">
            <router-link to="/dashboard/products/" class="text-sm font-medium text-purple-600 hover:text-purple-500 block cursor-pointer"><span aria-hidden="true"> &larr;</span> Back to product menu</router-link>
        </div>
        <label for="search" class="block text-sm font-medium text-gray-700">Search</label>
        <div class="flex justify-between items-center">
            
            <div class="flex items-center justify-center">
                <div class="flex items-center">
                    <div class="relative rounded-md shadow-sm">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                        <input type="text" v-model="search.phrase" name="search" id="search" class="focus:ring-purple-500 focus:border-purple-500 block w-full pl-10 text-sm border-gray-300 rounded-md" placeholder="Search for products.." />
                    </div>
                    
                </div>
                <div class="ml-2 flex justify-center items-center">
                    <button type="button" @click.prevent="searchForProducts"  :class="{'inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white': true, 'bg-purple-300': search.phrase.length === 0, 'bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500': search.phrase.length > 0}">
                        Search
                    </button>
                </div>                
            </div>
            <div class="flex items-center justify-center">
                <router-link v-if="$store.state.userData.systemCreator || $store.state.userData.permissions.editingProducts" to="/dashboard/product/add" type="button"  class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                    <PlusSmIcon class="h-6 w-6" aria-hidden="true" />
                </router-link>
            </div>    
        </div>
        <div class="mt-4" v-if="search.active">
            <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-700">
                Results of searching for: "{{search.lastResult.phrase}}" (Found: {{search.found.length}})
                <button @click.prevent="resetSearch()" type="button" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-purple-400 hover:bg-purple-200 hover:text-purple-500 focus:outline-none focus:bg-purple-500 focus:text-white">
                <span class="sr-only">Remove small option</span>
                <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                    <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                </svg>
                </button>
            </span>           
        </div>
        <Spinner v-if="loading"></Spinner>
        <div class="mt-4 flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 xl:px-0">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 pl-2 pr-0.5 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg" v-if="!loading">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">EAN / SKU</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Attributes</th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span class="sr-only">Edit</span>
                                </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white" v-if="!search.active">
                                <tr v-for="product in products" :key="product.id">
                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                        <div class="flex items-center">
                                        <div class="h-10 w-10 flex-shrink-0">
                                            <img class="h-10 w-10 rounded-md object-contain" :src="product.image.url.length>0 ? product.image.url : `https://avatars.dicebear.com/api/initials/${encodeURIComponent(`Product${product.id}`)}.svg`" :alt="product.name" />
                                        </div>
                                        <div class="ml-4">
                                            <div class="font-medium text-gray-900">{{product.name}} <span class="text-xs text-purple-300 hover:text-purple-500 select-none cursor-pointer" @click.prevent="copyToClipboard(product.id)">Copy ID</span></div>
                                            <div class="text-gray-500"><span class="text-xs" v-if="product.category.name.length>0">{{product.category.name}} <span class="text-xs opacity-60">(Category ID:{{product.category.id}})</span></span><span class="text-xs" v-if="product.category.name.length===0">No category</span></div>
                                        </div>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                        <div class="text-gray-500"><span class="text-xs" v-if="product.ean.length>0">{{product.ean}}</span><span class="text-xs font-medium opacity-60" v-if="product.ean.length===0">No EAN</span><span class="ml-2 text-xs text-purple-300 hover:text-purple-600 select-none cursor-pointer" v-if="product.ean.length>0" @click.prevent="copyToClipboard(product.ean)">Copy EAN</span></div>
                                        <div class="text-gray-500"><span class="text-xs" v-if="product.sku.length>0">{{product.sku}}</span><span class="text-xs font-medium opacity-60" v-if="product.sku.length===0">No SKU</span><span class="ml-2 text-xs text-purple-300 hover:text-purple-600 select-none cursor-pointer" v-if="product.sku.length>0" @click.prevent="copyToClipboard(product.sku)">Copy SKU</span></div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                        <span class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800" v-if="product.active">Active</span>
                                        <span class="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800" v-if="!product.active">Hidden</span>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                        <div>
                                            <span v-for="value in product.attribs.list.slice(0, 1)" :key="value.id" @click="showModal(product)"  class="select-none w-fit inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-100 text-gray-600 cursor-pointer hover:bg-purple-100 hover:text-purple-400"> {{value.parent.name}}: {{value.name}} </span>
                                            <span class="text-xs px-2.5 py-0.5 hover:text-purple-500 cursor-pointer" v-if="product.attribs.list.length-1 > 0" @click="showModal(product)">+ {{product.attribs.list.length-1}} more</span>
                                            <span class="text-xs" v-if="product.attribs.list.length === 0">No Attributes</span>
                                        </div>
                                    </td>
                                    <td  class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        <router-link v-if="$store.state.userData.systemCreator || $store.state.userData.permissions.editingProducts" :to="`/dashboard/product/edit?id=${product.id}`" target="_blank" class="text-purple-600 hover:text-purple-900"
                                        >
                                        <div class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500">Edit</div>
                                        <span class="sr-only">, {{ product.name }}</span></router-link>
                                        <router-link :to="`/dashboard/product/view?id=${product.id}`" target="_blank" class="text-purple-600 hover:text-purple-900"
                                        >
                                        <div type="button" class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500">View</div>
                                        <span class="sr-only">, {{ product.name }}</span></router-link>                                                      
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="divide-y divide-gray-200 bg-white" v-if="search.active">
                                <tr v-for="product in search.found" :key="product.id">
                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                        <div class="flex items-center">
                                        <div class="h-10 w-10 flex-shrink-0">
                                            <img class="h-10 w-10 rounded-md" :src="product.image.url.length>0 ? product.image.url : `https://avatars.dicebear.com/api/initials/${encodeURIComponent(`Product${product.id}`)}.svg`" :alt="product.name" />
                                        </div>
                                        <div class="ml-4">
                                            <div class="font-medium text-gray-900">{{product.name}} <span class="text-xs text-purple-300 hover:text-purple-500 select-none cursor-pointer" @click.prevent="copyToClipboard(product.id)">Copy ID</span></div>
                                            <div class="text-gray-500"><span class="text-xs" v-if="product.category.name.length>0">{{product.category.name}} <span class="text-xs opacity-60">(Category ID:{{product.category.id}})</span></span><span class="text-xs" v-if="product.category.name.length===0">No category</span></div>
                                        </div>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                        <div class="text-gray-500"><span class="text-xs" v-if="product.ean.length>0">{{product.ean}}</span><span class="text-xs font-medium opacity-60" v-if="product.ean.length===0">No EAN</span><span class="ml-2 text-xs text-purple-300 hover:text-purple-600 select-none cursor-pointer" v-if="product.ean.length>0" @click.prevent="copyToClipboard(product.ean)">Copy EAN</span></div>
                                        <div class="text-gray-500"><span class="text-xs" v-if="product.sku.length>0">{{product.sku}}</span><span class="text-xs font-medium opacity-60" v-if="product.sku.length===0">No SKU</span><span class="ml-2 text-xs text-purple-300 hover:text-purple-600 select-none cursor-pointer" v-if="product.sku.length>0" @click.prevent="copyToClipboard(product.sku)">Copy SKU</span></div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                        <span class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800" v-if="product.active">Active</span>
                                        <span class="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800" v-if="!product.active">Hidden</span>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                        <div>
                                            <span v-for="value in product.attribs.list.slice(0, 1)" :key="value.id" @click="showModal(product)"  class="select-none w-fit inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-100 text-gray-600 cursor-pointer hover:bg-purple-100 hover:text-purple-400"> {{value.parent.name}}: {{value.name}} </span>
                                            <span class="text-xs px-2.5 py-0.5 hover:text-purple-500 cursor-pointer" v-if="product.attribs.list.length-2 > 0" @click="showModal(product)">+ {{product.attribs.list.length-2}} more</span>
                                            <span class="text-xs" v-if="product.attribs.list.length === 0">No Attributes</span>
                                        </div>
                                    </td>
                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        <router-link :to="`/dashboard/product/edit?id=${product.id}`" target="_blank" class="text-purple-600 hover:text-purple-900"
                                        >Edit<span class="sr-only">, {{ product.name }}</span></router-link>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                       
                    </div>
                </div>
            </div>
        </div>
        <EmptyState class="mt-2 mb-2" v-if="(products.length === 0 && !search.active && !queryLoading) || (search.found.length === 0 && search.active && !queryLoading)"></EmptyState>
        <div class="flex items-center justify-center mt-4" v-if="queryLimitReached === false">
            <button type="button" @click.prevent="loadMoreProducts" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Load more..</button>
        </div>    
    </div>
</template>

<script>
    import Spinner  from '@/components/loadingSpinner.vue';
    import AttribValuesList from "@/components/ActualAttribsInProductModal.vue";
    import {db} from "@/firebase/gfbconf.js";
    import axios from 'axios';
    import EmptyState from '../components/EmptyState.vue';
    import { SearchIcon, PlusSmIcon  } from '@heroicons/vue/outline';
    export default {
        name: "ProductList",
        data()
        {
            return {
                loading: false,
                modal: {
                    open: false,
                    product: null,
                },
                search: {
                    phrase: "",
                    active: false,
                    found: [],
                    lastResult: null
                },
                products: [],
                queryLimit: 25,
                dbListener: null,
                lastLoaded: null,
                queryLimitReached: false
            }
        },
        components: {SearchIcon, PlusSmIcon,AttribValuesList,Spinner, EmptyState},
        async created()
        {
            this.dbListener = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Products").orderBy("meta.createdDate", "desc").limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.products = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                    this.products.push(doc.data());
                    this.lastLoaded = doc.data().meta.createdDate;
                });
            });
        },
        methods:
        {
            continueListening()
            {
                this.queryLimit = this.queryLimit + 10;
                this.dbListener = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Products").orderBy("meta.createdDate", "desc").limit(this.queryLimit)
                .onSnapshot((querySnapshot) => {
                    this.products = [];
                    if(querySnapshot.docs.length<this.queryLimit)
                    {
                        this.queryLimitReached = true;
                    }
                    else
                    {
                        this.queryLimitReached = false;
                    }
                    querySnapshot.forEach((doc) => {
                        this.products.push(doc.data());
                        this.lastLoaded = doc.data().meta.createdDate;
                    });
                });
            },
            async searchForProducts()
            {
                if(this.search.phrase.length>0)
                {
                    this.loading = true;
                    const result = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/elastic/search/products", {
                        system: this.$store.state.userData.system.id,
                        phrase: this.search.phrase
                    }).catch((error)=>
                    {
                        console.log(error);
                    });
                    this.loading = false;
                    if(result.data.status === "ok")
                    {
                        this.search.active = true;
                        this.search.lastResult = result.data;
                        this.search.found = result.data.result;
                    }
                    
                }
            },
            closeModal()
            {
                this.modal = {
                    open: false,
                    product: null
                }
            },
            loadMoreProducts()
            {
                this.dbListener();
                this.continueListening();      
            },
            showModal(product)
            {
                this.modal = {
                    open: true,
                    product: product
                }
            },
            copyToClipboard(id)
            {
                navigator.clipboard.writeText(id).then(()=>
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Copied!",
                        subheader: ``,
                        success: true
                    }); 
                })

            },
            async getProducts()
            {

            },
            resetSearch()
            {
                this.search.active = false
                this.search.phrase = "";
            }
        },
        computed:
        {
        },
        beforeUnmount() {
            this.dbListener();
        }
    }
</script>
<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
</style>