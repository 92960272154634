<template>
  <div class="flex h-full flex-col">
    <div class="flex sm:items-baseline sm:space-x-4 mt-2 mb-4">
      <div @click.prevent="previousPage" class="text-sm font-medium text-purple-600 hover:text-purple-500 block cursor-pointer"><span aria-hidden="true"> &larr;</span><span> Back to complaint menu</span></div>
    </div>
    <!-- <div class="flex items-center text-purple-600 my-3 text-sm cursor-pointer hover:text-purple-700" @click.prevent="previousPage">
      <ChevronLeftIcon class="h-4 w-4 text-purple-600" aria-hidden="true" /> <span>Back to complaint menu</span>
    </div> -->
    <header class="relative z-10 flex flex-none items-center justify-between border-b border-gray-200 pb-4 ">
      <div>
        <h1 class="text-lg font-semibold leading-6 text-gray-900">
          <time datetime="2022-01-22" class="inline">{{selected.titleDate}}</time>
        </h1>
        <p class="mt-1 text-sm text-gray-500">{{`Week ${selected.weekNum}`}}</p>
      </div>
    </header>
    <div class="flex flex-col-reverse xl:flex-row flex-auto overflow-hidden bg-white">
      <div ref="container" class="flex flex-auto flex-col overflow-auto xl:pr-2">

        

    <section class="mt-4" v-if="!loading">
      <h2 class="font-semibold text-gray-900">Events</h2>
      <EmptyState class="mt-2" v-if="selected.events.length === 0"></EmptyState>
      <ol class="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500" v-if="selected.events.length>0">
        <li class="py-4 flex items-center" v-for="(ev,evIndx) in selected.events" :key="evIndx">
          <time datetime="2022-01-19" class="w-28 text-xs flex-none">{{ev.evType}}</time>
          <p class="mt-2 flex-auto font-semibold text-gray-900 sm:mt-0">{{ev.data.id}}</p>
          <div :class="{'text-xs': true, 'text-blue-500': $store.state.user.uid === ev.data.assignedTo.attendantId }">{{substringText(getAttendantById(ev.data.assignedTo.attendantId))}}</div>
          <p class="flex-none sm:ml-6 ">
            <span v-if="!ev.data.completed" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"> In progress </span>
            <span v-if="ev.data.completed" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"> Completed </span>
          </p>
          <div class="ml-4 mr-4">
            <router-link :to="`/dashboard/complaints/edit?id=${ev.data.id}`" target="_blank" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-purple-700 bg-purple-100 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"><EyeIcon class="h-5 w-5" aria-hidden="true" /></router-link>
          </div>
        </li>
      </ol>
    </section>

    <section class="mt-4" v-if="!loading" :key="taskIteration">
      <h2 class="font-semibold text-gray-900">Team Tasks</h2>
      <div class="bg-white overflow-hidden sm:rounded-md mt-2">
      <EmptyState v-if="teamTasks.length === 0"></EmptyState>
        <ul role="list" class="divide-y m-0.5 shadow divide-gray-200">
          <li v-for="(task,tIndex) in teamTasks" :key="tIndex">
            <div class="block">
              <div class="flex items-center px-4 py-4 sm:px-6">
                <div class="min-w-0 flex-1 flex items-center">
                  <div class="flex-shrink-0">
                    <img class="h-12 w-12 rounded-full" :src="getUserAvatar(task.forUser)" alt="User avatar" />
                  </div>
                  <div class="min-w-0 flex-1 px-4 xl:grid xl:grid-cols-2 xl:gap-4">
                    <div>
                      <p class="text-sm font-medium text-blue-600 truncate"> {{substringText(getAttendantById(task.forUser))}}</p>
                      <p class="mt-1 flex items-center text-xs text-gray-500">
                        <span class="truncate">Created by {{task.meta.createdBy === $store.state.user.uid ? "you" : substringText(getAttendantById(task.meta.createdBy))}}</span>
                      </p>
                    </div>
                    <div class="block">
                      <div>
                        <p class="mt-2 flex items-center text-xs text-gray-500">
                          <ExclamationCircleIcon v-if="!task.completed" class="flex-shrink-0 mr-1.5 h-5 w-5 text-red-500" aria-hidden="true"></ExclamationCircleIcon>
                          <CheckCircleIcon v-if="task.completed" class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                          <span class="truncate">{{task.completed ? "Completed" : "Uncompleted"}}</span>
                        </p>
                        <p class="text-sm text-gray-900">
                        </p>
                      </div>
                    </div>
                    <div>
                      <span class="block text-sm">{{task.title}}</span>
                      <span class="block text-xs mt-1">{{task.description}}</span>
                    </div>


                  </div>
                </div>
                <div class="flex flex-col items-center select-none">
                  <div :class="{'mb-2 text-xs text-gray-400 flex justify-center border-2 rounded px-1 py-1 ': true, 'border-gray-100': task.label === 'NO LABEL', 'border-red-400': task.label === 'PRIORITY', 'w-1/2': ($store.state.user.uid === task.meta.createdBy && this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.teamTasks) && ($store.state.user.uid === task.forUser)}">
                    <div :class="{'uppercase': true, 'text-red-700 font-medium': task.label === 'PRIORITY', 'text-gray-200': task.label === 'NO LABEL', 'text-xs': task.label === 'OPTIONAL'}">{{task.label}}</div>

                  </div>
                  <div>
                    <button type="button" @click.prevent="deleteAsk = true; toDelete = tIndex;" v-if="$store.state.user.uid === task.meta.createdBy && this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.teamTasks" :class="{'inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500': true, 'mr-2': $store.state.user.uid === task.forUser}">Delete</button>
                    <button v-if="$store.state.user.uid === task.forUser" @click.prevent="setCompletion(task)" type="button" :class="{'inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2': true,  'text-green-700 bg-green-100 hover:bg-green-200 focus:ring-green-500': !task.completed,  'text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:ring-yellow-500': task.completed}">{{task.completed ? "Uncompleted" : "Complete"}}</button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

    </section>

    <Spinner v-if="loading" class="mt-4"></Spinner>
    
    





      </div>
      <div class="w-full xl:w-1/2 xl:max-w-md flex-none xl:border-l border-gray-100 pt-5 xl:py-10 px-1px xl:px-8 block">
        <div class="flex items-center text-center text-gray-900">
          <button type="button" @click.prevent="changeMonth('subtract')" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
            <span class="sr-only">Previous month</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <div class="flex-auto font-semibold">{{currentMonth.monthName}} {{currentMonth.year}}</div>
          <button type="button" @click.prevent="changeMonth('add')" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
            <span class="sr-only">Next month</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
          <div>Mo</div>
          <div>Tu</div>
          <div>We</div>
          <div>Th</div>
          <div>Fr</div>
          <div>Sa</div>
          <div>Su</div>
        </div>
        <div class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
          <button v-for="(day,ddx) in days" @click="setDay(day)" :key="day.date" type="button" :class="['py-1.5 hover:bg-gray-100 focus:z-10', day.month === currentMonth.month ? 'bg-white' : 'bg-gray-50', day === selected && day.monthNum === currentMonth.month && day.fullDate !== currentDay && 'text-gray-900', day === selected && 'font-medium bg-gray-100', day !== selected && 'text-gray-400', day.fullDate === currentDay && day !== selected && 'text-purple-600', ddx === 0 && 'rounded-tl-lg', ddx === 6 && 'rounded-tr-lg', ddx === days.length - 7 && 'rounded-bl-lg', , ddx === days.length - 1 && 'rounded-br-lg' ]">
            <time :datetime="day.fullDate" :class="{'mx-auto flex h-7 w-7 items-center justify-center rounded-full': true ,'bg-blue-600': false, 'bg-gray-900': false}">{{day.day}}</time>
          </button>
        </div>
        <form class="relative mt-2 xl:mb-56" v-if="this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.teamTasks">
          <h2 class="font-semibold text-gray-900 mt-4 mb-4">Add task</h2>
          <div class="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-purple-500 focus-within:ring-1 focus-within:ring-purple-500">
            <label for="title"  class="sr-only">Title</label>
            <input type="text" maxlength="35" v-model="taskForm.title" name="title" id="title" class="block w-full border-0 pt-2.5 text-lg font-medium placeholder-gray-500 focus:ring-0" placeholder="Title" />
            <label for="description" class="sr-only">Description</label>
            <textarea rows="3" v-model="taskForm.description" name="description" maxlength="60" id="description" class="block w-full border-0 py-0 resize-none placeholder-gray-500 focus:ring-0 text-sm" placeholder="Write a short description..." />
                <div aria-hidden="true">
        <div class="py-2">
          <div class="h-9" />
        </div>
        <div class="h-px" />
        <div class="py-2">
          <div class="py-px">
            <div class="h-9" />
          </div>
        </div>
      </div>
          </div>



          <div class="absolute bottom-0 inset-x-px">
            <!-- Actions: These are just examples to demonstrate the concept, replace/wire these up however makes sense for your project. -->
            <div class="border-t border-gray-200 px-2 py-2 flex flex-col-reverse items-center">
              <div class="w-full mt-2 mb-2">
                <button @click.prevent="addTeamTask" class="w-full  items-center px-2 sm:px-4 py-1.5 sm:py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"><div>Create new task</div></button>
              </div>
              <div class="flex items-center w-full">
                <!-- OLD VERSION -->
                <!-- <select v-model="taskForm.forUser" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                  <option v-for="user in $store.state.systemData.users" :key="user.id" :value="user.id">{{substringText(getAttendantById(user.id))}}</option>
                </select> -->
                

                  <Listbox as="div" v-model="taskForm.forUser" class="w-1/2">
                    <div class="relative">
                      <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-2 sm:pl-3 pr-8 sm:pr-10 py-1.5 sm:py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                        <span class="flex text-sm items-center truncate">{{substringText(getAttendantById(taskForm.forUser))}}</span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </ListboxButton>

                      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                          <ListboxOption as="template" v-for="user in $store.state.systemData.users" :key="user.id" :value="user.id" v-slot="{ active, selected }">
                            <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                              <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex text-sm items-center truncate']">{{substringText(getAttendantById(user.id))}}</span>

                              <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                              </span>
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </transition>
                    </div>
                  </Listbox>
                  <!-- OLD VERSION -->
                  <!-- <select v-model="taskForm.label" class="mt-1 ml-3 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                    <option v-for="label in labels" :value="label.value" :key="label.value">{{label.name}}</option>
                  </select> -->
                  <Listbox as="div" v-model="taskForm.label" class="w-1/2">
                    <div class="relative">
                      <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-2 sm:pl-3 pr-8 sm:pr-10 py-1.5 sm:py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                        <span class="flex text-sm items-center truncate">{{taskForm.label}}</span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </ListboxButton>

                      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                          <ListboxOption as="template" v-for="label in labels" :value="label.value" :key="label.value" v-slot="{ active, selected }">
                            <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                              <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex text-sm items-center truncate']">{{label.name}}</span>
                              <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                              </span>
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </transition>
                    </div>
                  </Listbox>

              </div>

            </div>
          </div>
        </form>


      </div>
    </div>
    
    <askForDelete v-if="deleteAsk" @accept="deleteTaskFromDatabase" :modalData="modalData" @closeModal="deleteAsk = false"></askForDelete>
  </div>
</template>
<script>
    import askForDelete from '@/components/AlertModal.vue';
    import { ChevronLeftIcon, ChevronRightIcon,EyeIcon, CheckCircleIcon, ExclamationCircleIcon, CheckIcon, SelectorIcon } from '@heroicons/vue/solid';
    import moment from 'moment';
    import EmptyState from '../components/EmptyState.vue';
    import Spinner  from '@/components/loadingSpinner.vue';
    import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
    
    //import axios from 'axios';
    //import {db,storage} from "@/firebase/gfbconf.js"
    export default {
        name: "CallendarOfComplaints",
        data()
        {
            return {
                toDelete: null,
                deleteAsk: false,
                modalData: {
                  headerText: "Delete task",
                  bodyText: "Are you sure to delete selected task? Data will be removed permanently.",
                  acceptButtonText: "Agree"
                },
                taskIteration: 0,
                taskForm: {
                  forUser: "",
                  label: "NO LABEL",
                  title: "",
                  description: "",
                  completed: false,
                  meta: {
                    createdBy: "",
                    createdDate: {}
                  }
                },
                teamTasks: [],
                labels: [
                {
                  name: "NO LABEL",
                  value: "NO LABEL"
                },
                {
                  name: "PRIORITY",
                  value: "PRIORITY"
                },{
                  name: "OPTIONAL",
                  value: "OPTIONAL"
                }],
                currentDay: "",
                loading: false,
                currentMonth: {
                    month: 6,
                    year: 2022,
                    monthName: "Loading.."
                },
                selected: {
                },
                days: [],
            }
        },
        components: {
             ChevronLeftIcon, ChevronRightIcon, EyeIcon, EmptyState, Spinner, CheckCircleIcon, ExclamationCircleIcon, askForDelete, Listbox, ListboxButton, ListboxOption, ListboxOptions, CheckIcon, SelectorIcon
        },
        async created()
        {
            this.setCurrentMonth();
            this.taskForm.forUser = this.$store.state.user.uid;
        },
        methods:
        {
            previousPage()
            {
              this.$router.push("/dashboard/complaints")
            },
            async deleteTaskFromDatabase()
            {
              try{
                await this.teamTasks[this.toDelete].ref.delete();
                this.teamTasks.pop(this.toDelete,1);
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Task has been deleted!",
                  subheader: ``,
                  success: true
                });
              }
              catch(error)
              {
                console.log(error);
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Something went wrong!",
                  subheader: ``,
                  success: false
                });
              }

            },
            async setCompletion(task)
            {
              try{
                task.completed = !task.completed;
                await task.ref.update({completed: task.completed});
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Task status has been set!",
                  subheader: ``,
                  success: true
                });
              }
              catch(error)
              {
                console.log(error);
                task.completed = !task.completed;
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Something went wrong!",
                  subheader: ``,
                  success: false
                });
              }
              
            },
            getUserAvatar(id)
            {
              let url = `https://avatars.dicebear.com/api/initials/${encodeURIComponent("NN")}.svg`
              for(let i=0; i<this.$store.state.systemData.users.length;i++)
              {
                if(this.$store.state.systemData.users[i].id.toString() === id.toString())
                {
                  url = `https://avatars.dicebear.com/api/initials/${encodeURIComponent(this.$store.state.systemData.users[i].displayName)}.svg`;
                }
              }
              return url              
            },
            async addTeamTask()
            {
              try{
                if(this.taskForm.title.length === 0 || this.taskForm.description.length === 0) throw "All fields are required"
                this.taskForm.forDay = this.selected.fullDate;
                this.taskForm.meta.createdBy = this.$store.state.user.uid;
                this.taskForm.meta.createdDate.timeAsText = moment().format("HH.MM");
                this.taskForm.meta.createdDate.unix = moment().unix();
                this.taskForm.meta.createdDate.asText = moment().format("YYYY-MM-DD");
                this.taskForm.meta.createdDate.year = moment().year().toString();
                this.taskForm.meta.createdDate.month = moment().month().toString();
                // Check if year doc exists
                const yearDoc = await this.$store.state.userData.system.ref.collection("TeamTasks").doc(this.selected.year.toString()).get();
                if(!yearDoc.exists)
                {
                  await this.$store.state.userData.system.ref.collection("TeamTasks").doc(this.selected.year.toString()).set({year: this.selected.year.toString(), createdDate: moment().unix()});
                }

                const res = await this.$store.state.userData.system.ref.collection("TeamTasks").doc(this.selected.year.toString()).collection(this.selected.month.toString()).add(this.taskForm);
                await res.update({ref: res, id: res.id})
                 
                let reload = await this.$store.state.userData.system.ref.collection("TeamTasks").doc(this.selected.year.toString()).collection(this.selected.month.toString()).where("meta.createdDate.asText", "==", this.selected.fullDate.toString()).get();
                this.teamTasks = [];
                for(let i=0; i<reload.docs.length;i++)
                {
                  this.teamTasks.push(reload.docs[i].data())
                }    
                
                this.$store.state.userData.system.ref.collection("Events").add(
                {
                  user: {
                    name: this.$store.state.user.displayName,
                    photo: this.$store.state.user.photoURL
                  },
                  creationDate: moment().toDate(),
                  eventName: `Added new task for ${this.getAttendantById(this.taskForm.forUser)} (${this.selected.fullDate}).`
                })

                this.$store.commit('setNotification',{
                  show: true,
                  head: "Task has been created with success!",
                  subheader: ``,
                  success: true
                });

                this.taskForm.forUser = this.$store.state.user.uid;
                this.taskForm.title = "";
                this.taskForm.description = "";
                this.taskForm.label = "NO LABEL";
                this.getData();
                
              }
              catch(error)
              {
                console.log(error);
                this.$store.commit('setNotification',{
                  show: true,
                  head: error === "All fields are required" ? error : "Something went wrong",
                  subheader: ``,
                  success: false
                });
              }
            },
            // TEST
            substringText(name)
            {
              if(name.length>14)
              {
                name = name.substring(0,14)+'...'
              }
              return name;
            },
            getAttendantById(id)
            {
              let attendantName = "DELETED";
              for(let i=0; i<this.$store.state.systemData.users.length;i++)
              {
                if(this.$store.state.systemData.users[i].id.toString() === id.toString())
                {
                  attendantName = this.$store.state.systemData.users[i].displayName;
                }
              }
              return attendantName
            },
            sortTasks()
            {
              this.teamTasks.sort((a,b)=>
              {
                if(a.meta.createdDate.unix > b.meta.createdDate.unix) return 1;
                if(a.meta.createdDate.unix < b.meta.createdDate.unix) return -1;
                return 0;
              })
              this.teamTasks.reverse();
            },
            async getData()
            {
              this.loading = true;
              this.selected.events = [];
              this.teamTasks = [];
              const scheduledEnd = await this.$store.state.userData.system.ref.collection("Complaints").where("scheduledEnd.asText","==", this.selected.fullDate).get();
              const teamTasks = await this.$store.state.userData.system.ref.collection("TeamTasks").doc(this.selected.year.toString()).collection(this.selected.month.toString()).where("forDay", "==", this.selected.fullDate.toString()).get();
              

              for(let i=0; i<scheduledEnd.docs.length; i++)
              {
                let data = scheduledEnd.docs[i].data();
                let eventObj = {
                  evType: "Scheduled end",
                  objType: "Complaint",
                  data: data
                };
                this.selected.events.push(eventObj);
              }

              for(let i=0; i<teamTasks.docs.length;i++)
              {
                this.teamTasks.push(teamTasks.docs[i].data())
              }

              this.sortTasks();

              setTimeout(()=>
              {
                this.loading = false;
              },300)
              
              
            },
            async setDay(day)
            {
                this.selected = day;
                await this.getData()
            },
            addRestOfDaysToCallendar()
            {
                let amountFromStart  = this.days[0].dayOfWeek-7*(-1);
                let amountFromEnd = this.days[this.days.length-1].dayOfWeek;
                let currentDayFromStart = moment(this.days[0].fullDate,"YYYY-MM-DD");
                let currentDayFromEnd = moment(this.days[this.days.length-1].fullDate,"YYYY-MM-DD");

                for(let i=0; i<amountFromStart-1; i++)
                {
                    let currentDay = currentDayFromStart.subtract(1, 'days')
                    this.days.unshift({
                        fullDate: currentDay.format("YYYY-MM-DD"), 
                        dayOfWeek: currentDay.day(),
                        year: currentDay.year(),
                        month: currentDay.month(),
                        day: currentDay.date(),
                        monthName: currentDay.format('MMMM'),
                        unix: currentDay.unix(),
                        weekNum: currentDay.week(),
                        events: [],
                        titleDate: currentDay.format("Do MMMM YYYY")
                    })
                }

                for(let i=0; i<=6-amountFromEnd; i++)
                {
                    let currentDay = currentDayFromEnd.add(1, 'days')
                    this.days.push({
                        fullDate: currentDay.format("YYYY-MM-DD"), 
                        dayOfWeek: currentDay.day(),
                        year: currentDay.year(),
                        month: currentDay.month(),
                        day: currentDay.date(),
                        monthName: currentDay.format('MMMM'),
                        unix: currentDay.unix(),
                        weekNum: currentDay.week(),
                        events: [],
                        titleDate: currentDay.format("Do MMMM YYYY")
                    })
                }

                if(this.days.length/7 >= 6)
                {
                    for(let i=0; i<7;i++)
                    {
                        this.days.shift();
                    }
                }

                
                
            },
            getDaysOfMonth()
            {
                this.days = [];
                let current = `01-${this.currentMonth.month+1}-${this.currentMonth.year}`
                let amountOfDaysInMonth = moment(current,"DD-M-YYYY").daysInMonth();

                for(let i=1; i<amountOfDaysInMonth; i++)
                {
                    let momentObj = moment(`${i}-${this.currentMonth.month+1}-${this.currentMonth.year}`,"D-M-YYYY")
                    let day = {
                        fullDate: momentObj.format("YYYY-MM-DD"), 
                        dayOfWeek: momentObj.day(),
                        year: momentObj.year(),
                        month: momentObj.month(),
                        day: momentObj.date(),
                        monthName: momentObj.format('MMMM'),
                        unix: momentObj.unix(),
                        weekNum: momentObj.week(),
                        events: [],
                        titleDate: momentObj.format("Do MMMM YYYY")
                    }
                    this.days.push(day);
                }

                this.addRestOfDaysToCallendar();

            },
            setCurrentMonth()
            {
                this.currentMonth.year = moment().year();
                this.currentMonth.month = moment().month();
                this.currentMonth.monthName = moment().format('MMMM');
                this.currentDay = moment().format('YYYY-MM-DD');
                this.getDaysOfMonth();
                let currentDayDate
                for(let i = 0; i < this.days.length; i++) {
                  if(this.days[i].fullDate === this.currentDay) {
                    currentDayDate = this.days[i]
                  }
                }
                this.setDay(currentDayDate)
            },
            changeMonth(type)
            {
                let current = `01-${this.currentMonth.month+1}-${this.currentMonth.year}`
                if(type === 'add')
                {
                    this.currentMonth.year = moment(current,"DD-M-YYYY").add(1, 'months').year();
                    this.currentMonth.month = moment(current,"DD-M-YYYY").add(1, 'months').month();
                    this.currentMonth.monthName = moment(current,"DD-M-YYYY").add(1, 'months').format('MMMM');
                }
                if(type === 'subtract')
                {
                    this.currentMonth.year = moment(current,"DD-M-YYYY").subtract(1, 'months').year();
                    this.currentMonth.month = moment(current,"DD-M-YYYY").subtract(1, 'months').month();
                    this.currentMonth.monthName = moment(current,"DD-M-YYYY").subtract(1, 'months').format('MMMM');
                }
                this.getDaysOfMonth();

            },

        },
    }
</script>
<style lang="scss" scoped>

</style>