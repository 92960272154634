<template>
  <div id="ticketList">
    <div class="flex items-center justify-between sm:space-x-4 mt-2 mb-4">
        <router-link to="/dashboard/complaints/" class="text-sm font-medium text-purple-600 hover:text-purple-500 block cursor-pointer"><span aria-hidden="true"> &larr;</span> Back to complaint menu</router-link>
        <button @click.prevent="getAllTickets" v-if="!queryLoading" type="button" class="inline-flex items-center rounded border border-transparent bg-purple-100 px-2.5 py-1.5 text-xs font-medium text-purple-700 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"><RefreshIcon class="h-4 w-4" /></button>
    </div>

    <Spinner v-if="queryLoading"></Spinner>
    <div class="mt-4 flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 xl:px-0" v-if="!queryLoading">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 pl-2 pr-0.5 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg" v-if="!loading">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                            <tr>
                              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Phone</th>
                              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Creation Date</th>
                              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Products</th>
                              <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                  <span class="sr-only">Edit</span>
                              </th>
                            </tr>
                        </thead>

                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="(ticket) in tickets" :key="ticket.formId">
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    <div class="flex items-center text-xs font-medium" v-if="ticket.clientCredentials.name.length !== 0">
                                      {{ticket.clientCredentials.name.substring(0,22)}}<span v-if="ticket.clientCredentials.name.length>22">..</span>
                                    </div>
                                    <div class="flex items-center text-xs font-medium" v-if="ticket.clientCredentials.name.length === 0">
                                      ---
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none font-medium">
                                  <span v-if="ticket.clientCredentials.email.length !== 0">
                                    {{ticket.clientCredentials.email.substring(0,22)}}<span v-if="ticket.clientCredentials.email.length>22">..</span>
                                  </span>
                                  <span v-if="ticket.clientCredentials.email.length === 0">
                                    ---
                                  </span>
                                  
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none font-medium">
                                  <span v-if="ticket.clientCredentials.phone.length !== 0">{{ticket.clientCredentials.phone.substring(0,22)}}<span v-if="ticket.clientCredentials.phone.length>22">..</span></span>
                                  <span v-if="ticket.clientCredentials.phone.length === 0">---</span>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none font-medium">
                                  {{ticket.createdDate.asText}}
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none font-medium">
                                  {{ticket.products.length === 1 && ticket.products.length !== 0 ? `${ticket.products[0].name.substring(0,22)}` : `${ticket.products[0].name.substring(0,22)} + ${ticket.products.length-1} more`}}
                                </td>
                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                      <router-link :to="`/dashboard/complaints/pending/review?id=${ticket.formId}`" target="_blank" class="text-purple-600 hover:text-purple-900"
                                      >
                                      <div class="relative inline-flex items-center px-4 py-2 rounded-md  border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500">Review</div>
                                      </router-link>
                                </td>




                            </tr>
                        </tbody>


                    </table>
                    
                </div>
            </div>
        </div>
    </div>
    <EmptyState class="mt-2 mb-2" v-if="tickets.length === 0 && !queryLoading"></EmptyState>
    
    
  </div>
</template>

<script>
    import {RefreshIcon} from '@heroicons/vue/outline';
    import EmptyState from '../components/EmptyState.vue';
    // import EmptyState from '../components/EmptyState.vue';
    import Spinner  from '@/components/loadingSpinner.vue';
    export default {
        name: "ticketList",
        components: {RefreshIcon,Spinner,EmptyState},
        data: function () {
            return {
                queryLoading: false,
                loading: false,
                tickets: []
            }
        },
        async created()
        {
          await this.getAllTickets();
        },
        methods: {
          async getAllTickets()
          {
            try{
              this.queryLoading = true;
              this.tickets = [];
              const result = await this.$store.state.userData.system.ref.collection("ComplaintsBuffer").get();
              for(let i=0;i<result.docs.length;i++)
              {
                this.tickets.push(result.docs[i].data());
              }
              setTimeout(()=>
              {
                this.queryLoading = false;
              },350)
              
            }
            catch(error)
            {
              console.log(error);
              this.$router.push("/dashboard/complaints")
            }


          }
        }
    };
</script>